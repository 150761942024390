import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Pagination,
  FunctionField,
  DateField,
  CheckboxGroupInput,
  SimpleForm,
} from "react-admin";
import Button from "@material-ui/core/Button";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import DealFilter from "./DealsFiltersTest";
import AddColumns from "./AddColumns";
import ListExport from "./ListExport";
import { ExportButton } from "ra-ui-materialui";
import axios from "axios";
import queryString from "query-string";
import XLSX from "xlsx";
import windowSize from "react-window-size";
import moment from "moment";
import { Modal, Checkbox } from "antd";

const postRowStyle = (record, index) => ({
  backgroundColor: index % 2 == 0 ? "#ecf2f8" : "white",
});

var approval = [];

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

// Could evolve to take the format as parameter
// If no unit in param, will return without format => working as intended but could evolve if need be (check format maybe...)
const displayAmount = (nb, div, unit, fixed) => {
  if (isNaN(nb)) return nb;
  const numberFormat = new Intl.NumberFormat("fr-FR");
  var finalNb = nb / div;
  if (nb >= div / 10) finalNb = finalNb.toFixed(fixed);
  if (!unit) return finalNb;
  else return numberFormat.format(finalNb) + "\xa0" + unit;
};

let state = {
  col_list: [
    { name: "OfferID", checked: true, family_name: "GENERAL", width: "auto" },
    { name: "Target", checked: true, family_name: "GENERAL", width: "auto" },
    { name: "Target marketplace", checked: false, family_name: "GENERAL", width: "auto" },
    { name: "Business sector", checked: false, family_name: "GENERAL", width: "auto" },
    { name: "Bidder", checked: true, family_name: "GENERAL", width: "auto" },
    { name: "Bidder acting in concert", checked: false, family_name: "GENERAL", width: "auto" },
    {
      name: "Controlling shareholders of Bidder",
      checked: false,
      family_name: "GENERAL",
      width: "auto",
    },
    {
      name: "PE/VC Bidder -   Family office",
      checked: false,
      family_name: "GENERAL",
      width: "auto",
    },
    { name: "Pre-offer", checked: false, family_name: "GENERAL", width: "auto" },
    { name: "Filing date", checked: false, family_name: "GENERAL", width: "auto" },
    { name: "Clearance date", checked: true, family_name: "GENERAL", width: "auto" },
    { name: "Offer type", checked: true, family_name: "GENERAL", width: "300px" },
    { name: "Competing offer", checked: false, family_name: "GENERAL", width: "auto" },
    { name: "Mandatory/Voluntary", checked: false, family_name: "GENERAL", width: "auto" },
    { name: "Put up shut up", checked: false, family_name: "GENERAL", width: "auto" },
    { name: "Friendly/Non-solicited", checked: false, family_name: "GENERAL", width: "auto" },
    {
      name: "Block purchase (including contribution of Target shares) % acquired",
      checked: false,
      family_name: "BLOCK PURCHASE",
      width: "auto",
    },
    {
      name: "Direct/Indirect block purchase",
      checked: false,
      family_name: "BLOCK PURCHASE",
      width: "auto",
    },
    {
      name: "Top up granted to block sellers",
      checked: false,
      family_name: "BLOCK PURCHASE",
      width: "300px",
    },
    {
      name: "Block price adjustment/Additional price",
      checked: false,
      family_name: "BLOCK PURCHASE",
      width: "auto",
    },
    {
      name: "Top up granted to minority shareholders",
      checked: false,
      family_name: "OFFER PRICE",
      width: "auto",
    },
    {
      name: "Offer price adjustment/Additional offer price",
      checked: false,
      family_name: "OFFER PRICE",
      width: "auto",
    },
    {
      name: "Squeeze out kicker - %/Offer price",
      checked: false,
      family_name: "OFFER PRICE",
      width: "auto",
    },
    {
      name: "Reimbursement of brokerage fees",
      checked: false,
      family_name: "OFFER PRICE",
      width: "auto",
    },
    {
      name: "Offer price/Exchange ratio increase prior to clearance",
      checked: false,
      family_name: "OFFER PRICE",
      width: "auto",
    },
    {
      name: "Offer price excluding or including the dividend",
      checked: false,
      family_name: "OFFER PRICE",
      width: "auto",
    },
    {
      name: "Offer price increase/Surenchère %",
      checked: false,
      family_name: "OFFER PRICE",
      width: "auto",
    },
    { name: "TOA", checked: false, family_name: "AGREEMENTS IMPACTING THE OFFER", width: "300px" },
    {
      name: "% break-up fee  /Deal value",
      checked: false,
      family_name: "AGREEMENTS IMPACTING THE OFFER",
      width: "auto",
    },
    {
      name: "% reverse break-up fee  /Deal value",
      checked: false,
      family_name: "AGREEMENTS IMPACTING THE OFFER",
      width: "auto",
    },
    {
      name: "Undertaking to tender/not to tender",
      checked: false,
      family_name: "AGREEMENTS IMPACTING THE OFFER",
      width: "300px",
    },
    {
      name: "Escrow of Target shares",
      checked: false,
      family_name: "AGREEMENTS IMPACTING THE OFFER",
      width: "auto",
    },
    {
      name: "Reinvestment",
      checked: false,
      family_name: "AGREEMENTS IMPACTING THE OFFER",
      width: "300px",
    },
    {
      name: "Management package (other than reinvestment)",
      checked: false,
      family_name: "AGREEMENTS IMPACTING THE OFFER",
      width: "300px",
    },
    {
      name: "Liquidity agreements Fixed/Variable price",
      checked: false,
      family_name: "AGREEMENTS IMPACTING THE OFFER",
      width: "auto",
    },
    {
      name: "Other agreements",
      checked: false,
      family_name: "AGREEMENTS IMPACTING THE OFFER",
      width: "300px",
    },
    {
      name: "Pre-offer Regulatory condition precedent",
      checked: false,
      family_name: "PRE-OFFER CONDITIONS",
      width: "auto",
    },
    {
      name: "Pre-offer Other condition precedent",
      checked: false,
      family_name: "PRE-OFFER CONDITIONS",
      width: "auto",
    },
    {
      name: "Opening of the offer subject to regulatory approval",
      checked: false,
      family_name: "OFFER CONDITIONS",
      width: "auto",
    },
    {
      name: "Mandatory level of acceptances Only applicable since 30 June 2014",
      checked: false,
      family_name: "OFFER CONDITIONS",
      width: "auto",
    },
    {
      name: "Optional level of acceptances",
      checked: false,
      family_name: "OFFER CONDITIONS",
      width: "auto",
    },
    {
      name: "Other offer conditions",
      checked: false,
      family_name: "OFFER CONDITIONS",
      width: "auto",
    },
    { name: "Squeeze out", checked: false, family_name: "BIDDER'S INTENTIONS", width: "auto" },
    { name: "Merger", checked: false, family_name: "BIDDER'S INTENTIONS", width: "auto" },
    { name: "Delisting", checked: false, family_name: "BIDDER'S INTENTIONS", width: "auto" },
    {
      name: "Subsequent OPR-RO",
      checked: false,
      family_name: "BIDDER'S INTENTIONS",
      width: "auto",
    },
    {
      name: "Exceptional dividend distribution/  Debt push down",
      checked: false,
      family_name: "BIDDER'S INTENTIONS",
      width: "auto",
    },
    {
      name: "Disclosure of synergies' amount",
      checked: false,
      family_name: "BIDDER'S INTENTIONS",
      width: "auto",
    },
    { name: "New strategy", checked: false, family_name: "BIDDER'S INTENTIONS", width: "auto" },
    {
      name: "Other relevant intentions",
      checked: false,
      family_name: "BIDDER'S INTENTIONS",
      width: "auto",
    },
    {
      name: "Securities targeted",
      checked: false,
      family_name: "SECURITIES TARGETED/EXCLUDED",
      width: "300px",
    },
    {
      name: "Securities excluded",
      checked: false,
      family_name: "SECURITIES TARGETED/EXCLUDED",
      width: "300px",
    },
    {
      name: "Target value for 100% (other than OPRA/Buy back)",
      checked: false,
      family_name: "DEAL VALUE",
      width: "auto",
    },
    {
      name: "Amount of the offer (Targeted securities only)",
      checked: false,
      family_name: "DEAL VALUE",
      width: "auto",
    },
    { name: "Bidder's costs", checked: false, family_name: "COST", width: "auto" },
    {
      name: "Cost of the block purchase included in Bidder's costs ?",
      checked: false,
      family_name: "COST",
      width: "auto",
    },
    {
      name: "% of Bidder's costs/Target value for 100%",
      checked: false,
      family_name: "COST",
      width: "auto",
    },
    {
      name: "Simultaneous offer abroad/on listed subsidiary of Target",
      checked: false,
      family_name: "GENERAL",
      width: "auto",
    },
    {
      name: "Data room/Inside information",
      checked: false,
      family_name: "BP - DATAROOM",
      width: "auto",
    },
    {
      name: "BP or other documentation available to Bidder and expert",
      checked: false,
      family_name: "BP - DATAROOM",
      width: "auto",
    },
    { name: "Net Asset Value", checked: false, family_name: "MULTICRITERIA", width: "auto" },
    {
      name: "Revalued Net Asset Value",
      checked: false,
      family_name: "MULTICRITERIA",
      width: "auto",
    },
    { name: "Trading prices", checked: false, family_name: "MULTICRITERIA", width: "auto" },
    {
      name: "Financial analysts price targets",
      checked: false,
      family_name: "MULTICRITERIA",
      width: "auto",
    },
    { name: "DCF", checked: false, family_name: "MULTICRITERIA", width: "auto" },
    {
      name: "Dividend discount model",
      checked: false,
      family_name: "MULTICRITERIA",
      width: "auto",
    },
    {
      name: "Multiples of comparable companies",
      checked: false,
      family_name: "MULTICRITERIA",
      width: "auto",
    },
    { name: "Precedent transactions", checked: false, family_name: "MULTICRITERIA", width: "auto" },
    { name: "Recent transactions", checked: false, family_name: "MULTICRITERIA", width: "auto" },
    { name: "Other criteria", checked: false, family_name: "MULTICRITERIA", width: "auto" },
    { name: "SPOT", checked: false, family_name: "PREMIUM", width: "auto" },
    { name: "1M/20 j", checked: false, family_name: "PREMIUM", width: "auto" },
    { name: "2M", checked: false, family_name: "PREMIUM", width: "auto" },
    { name: "3M/60 j", checked: false, family_name: "PREMIUM", width: "auto" },
    { name: "6M", checked: false, family_name: "PREMIUM", width: "auto" },
    { name: "1Y", checked: false, family_name: "PREMIUM", width: "auto" },
    {
      name: "Comments relating to premium  (if necessary)",
      checked: false,
      family_name: "PREMIUM",
      width: "auto",
    },
    {
      name: "Initial Bidder's stake C = share capital  VR = voting rights",
      checked: false,
      family_name: "BIDDER'S STAKE - RESULTS",
      width: "auto",
    },
    {
      name: "At filing % owned by Bidder C = share capital  VR = voting rights",
      checked: false,
      family_name: "BIDDER'S STAKE - RESULTS",
      width: "auto",
    },
    {
      name: "Before reopening of the offer % owned by Bidder C = share capital  VR = voting rights",
      checked: false,
      family_name: "BIDDER'S STAKE - RESULTS",
      width: "auto",
    },
    {
      name: "End of the offer  % owned by  Bidder C = share capital  VR = voting rights",
      checked: true,
      family_name: "BIDDER'S STAKE - RESULTS",
      width: "300px",
    },
    {
      name: "Squeeze out following the offer",
      checked: false,
      family_name: "SQUEEZE OUT",
      width: "auto",
    },
    {
      name: "Squeeze out threshold calculation",
      checked: false,
      family_name: "SQUEEZE OUT",
      width: "300px",
    },
    { name: "Market purchase %", checked: false, family_name: "SQUEEZE OUT", width: "auto" },
    {
      name: "Squeeze out following a subsequent offer",
      checked: false,
      family_name: "SQUEEZE OUT",
      width: "auto",
    },
    { name: "Green mailing", checked: false, family_name: "SQUEEZE OUT", width: "auto" },
    {
      name: "Fairness opinion",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "AMF request for appointing a new expert Art. 261-1-1 II  Applicable since 10 February 2020",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "AMF objection to the appointment of the expert Art. 261-1-1 III Applicable since 10 February 2020",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Independent expert",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Independent expert's fees",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Ad hoc committee Mission",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Conflicted directors",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Directors' abstention if conflicted",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "300px",
    },
    {
      name: "Board attendance",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Target recommendation",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Tender of treasury shares",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Workers council opinion",
      checked: false,
      family_name: "FAIRNESS OPINION - TARGET RECOMMENDATION",
      width: "auto",
    },
    {
      name: "Litigation/Complaints  from minority shareholders",
      checked: false,
      family_name: "GENERAL",
      width: "300px",
    },
    { name: "Deal Bredin Prat", checked: false, family_name: "GENERAL", width: "auto" },
    { name: "Comments", checked: true, family_name: "GENERAL", width: "300px" },
    { name: "Conformité AMF", checked: true, family_name: "LINK TO AMF DOCUMENTS", width: "auto" },
    {
      name: "Note d'information",
      checked: true,
      family_name: "LINK TO AMF DOCUMENTS",
      width: "auto",
    },
    { name: "Note en réponse", checked: true, family_name: "LINK TO AMF DOCUMENTS", width: "auto" },
    {
      name: "Mise en œuvre du RO",
      checked: true,
      family_name: "LINK TO AMF DOCUMENTS",
      width: "auto",
    },
    {
      name: "Complément note d'information 1 (surenchère)",
      checked: false,
      family_name: "LINK TO AMF DOCUMENTS",
      width: "auto",
    },
    {
      name: "Complément note d'information (surenchère)",
      checked: false,
      family_name: "LINK TO AMF DOCUMENTS",
      width: "auto",
    },
  ],
  right: false,
  modal: false,
  checked: [],
};

class DealList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = state;
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleCol = this.handleCol.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentWillUnmount() {
    state = this.state;
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  toggleDrawer() {
    this.setState({ right: !this.state.right });
  }

  handleCol(elt) {
    var cloneColList = JSON.parse(JSON.stringify(this.state)).col_list;
    cloneColList.find((x) => x.name === elt.name).checked = !elt.checked;
    this.setState({ col_list: cloneColList });
  }

  handleUsers = (users) => {
    approval = [];
    approval.push(users);
  };

  render() {
    const { ...props } = this.props;
    const values = queryString.parse(this.props.location.search);
    var col = [...this.state.col_list];
    var fieldsToSend = [];

    /* -----   Custom amount field for Datagrid   ----- */
    const AmountField = ({ record, source }) => {
      if (record[source] === "N/A" || record[source] === "Failed") {
        return <span>{record[source]}</span>;
      } else {
        if (
          record[source] === "" ||
          record[source] === undefined ||
          isNaN(record[source]) === true
        ) {
          return <span></span>;
        } else {
          if (source === "Target value for 100% (other than OPRA/Buy back)") {
            let nb = displayAmount(record[source], 1000000, "M€", 2);
            return <span>{nb}</span>;
          }
          if (source === "Amount of the offer (Targeted securities only)") {
            let nb = displayAmount(record[source], 1000, "k€", 1);
            return <span>{nb}</span>;
          }
          if (source === "Bidder's costs" || source === "Independent expert's fees") {
            let nb = displayAmount(record[source], 1000, "k€", 0);
            return <span>{nb}</span>;
          }
        }
      }
    };

    /* -----   Custom URL field for Datagrid   ----- */
    const UrlField = ({ record, source }) => {
      if (record[source] === "N/A" || record[source] === "Failed") {
        return <span>{record[source]}</span>;
      } else {
        if (record[source] === "" || record[source] === undefined) {
          return <span></span>;
        } else {
          if (source === "Conformité AMF") {
            return (
              <a
                href={record[source]}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Conformité
              </a>
            );
          }
          if (source === "Note d'information") {
            return (
              <a
                href={record[source]}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Note d'information
              </a>
            );
          }
          if (source === "Note en réponse") {
            return (
              <a
                href={record[source]}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Note en réponse
              </a>
            );
          }
          if (source === "Mise en œuvre du RO") {
            return (
              <a
                href={record[source]}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Retrait obligatoire
              </a>
            );
          }
          if (source === "Complément note d'information 1 (surenchère)") {
            return (
              <a
                href={record[source]}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Lien
              </a>
            );
          }
          if (source === "Complément note d'information (surenchère)") {
            return (
              <a
                href={record[source]}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Lien
              </a>
            );
          }
        }
      }
    };

    const UrlShowDeal = ({ record, source }) => {
      //console.log(record.id);
      return (
        <a
          style={{ whiteSpace: "pre-line" }}
          href={`/tenderoffers/${record.id}/show`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {record[source]}
        </a>
      );
    };

    /* -----   Columns displayed in DataGrid && update selected columns   ----- */
    const ColToDisplay = col.map((elt, i) => {
      if (elt.checked == true) {
        fieldsToSend.push(elt.name);
        if (elt.name == "ID") {
          return <TextField key={i} label={elt.name} source={elt.name} />;
        } else {
          if (elt.name == "Clearance date" || elt.name == "Filing date") {
            return <DateField key={i} label={elt.name} source={elt.name} locales="fr-FR" />;
          }
          if (elt.name == "OfferID") {
            return <UrlShowDeal key={i} label={elt.name} source={elt.name} />;
          }
          if (elt.name == "Target") {
            return <UrlShowDeal key={i} label={elt.name} source={elt.name} />;
          }
          if (
            elt.name == "Conformité AMF" ||
            elt.name == "Note d'information" ||
            elt.name == "Note en réponse" ||
            elt.name == "Mise en œuvre du RO" ||
            elt.name == "Complément note d'information 1 (surenchère)" ||
            elt.name == "Complément note d'information (surenchère)"
          ) {
            return (
              <UrlField
                key={i}
                label="Lien"
                source={elt.name}
                sortable={false}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            );
          } else {
            if (elt.name == "Target value for 100% (other than OPRA/Buy back)")
              return (
                <AmountField
                  key={i}
                  label="Target value for 100%, in M€ (other than OPRA/Buy back)"
                  source={elt.name}
                  sortable={false}
                />
              );
            else if (elt.name == "Amount of the offer (Targeted securities only)")
              return (
                <AmountField
                  key={i}
                  label="Amount of the offer, in k€ (Targeted securities only)"
                  source={elt.name}
                  sortable={false}
                />
              );
            else if (elt.name == "Bidder's costs")
              return (
                <AmountField
                  key={i}
                  label="Bidder's costs, in k€"
                  source={elt.name}
                  sortable={false}
                />
              );
            else if (elt.name == "Independent expert's fees")
              return (
                <AmountField
                  key={i}
                  label="Independent expert's fees, in k€"
                  source={elt.name}
                  sortable={false}
                />
              );
            else {
              if (
                elt.name == "SPOT" ||
                elt.name == "1M/20 j" ||
                elt.name == "2M" ||
                elt.name == "3M/60 j" ||
                elt.name == "6M" ||
                elt.name == "1Y" ||
                elt.name == "% of Bidder's costs/Target value for 100%" ||
                elt.name == "% break-up fee  /Deal value" ||
                elt.name == "% reverse break-up fee  /Deal value" ||
                elt.name == "Initial Bidder's stake C = share capital  VR = voting rights" ||
                elt.name == "At filing % owned by Bidder C = share capital  VR = voting rights" ||
                elt.name ==
                  "End of the offer  % owned by  Bidder C = share capital  VR = voting rights" ||
                elt.name ==
                  "Before reopening of the offer % owned by Bidder C = share capital  VR = voting rights"
              ) {
                //return <NumberField key={i} label={elt.name} source={elt.name} options={{ style: 'percent' }} />
                return (
                  <FunctionField
                    key={i}
                    style={{ whiteSpace: "pre-line" }}
                    label={elt.name}
                    source={elt.name}
                    render={(record) =>
                      `${
                        record[elt.name] ||
                        (record[elt.name] === 0 ? record[elt.name] + "%" : "N/A")
                      }`
                    }
                  />
                );
              } else {
                if (elt.width == "300px") {
                  return (
                    <TextField
                      style={{ whiteSpace: "pre-line" }}
                      key={i}
                      label={elt.name}
                      source={elt.name}
                      sortable={false}
                      cellClassName="offerCell"
                    />
                  );
                } else {
                  return (
                    <TextField
                      style={{ whiteSpace: "pre-line" }}
                      key={i}
                      label={elt.name}
                      source={elt.name}
                      sortable={false}
                    />
                  );
                }
              }
            }
          }
        }
      }
    });

    /* -----   Export Excel   ----- */
    const exporter = (tenderoffers) => {
      console.log(fieldsToSend.length);
      if (fieldsToSend.length > 50) {
        alert(
          "Sorry ! You are not allowed to export more than 50 columns. Please remove a few columns in order to proceed or contact Nathalie Legendre."
        );
      } else {
        alert(
          "Votre demande d’export a été envoyée à la personne responsable de votre dossier ainsi qu’à Nathalie Legendre. Ils vous adresseront le tableau dans les meilleurs délais."
        );
        var data = tenderoffers.map((tenderoffer) => ({
          ...tenderoffer,
        }));
        // Looping through data (Array) which contain elements (Object) corresponding to a line in the tenderoffers tab
        var colToRemoveFromFields = []; // if you need to replace an element key with another one, add it to this array to later replace it in the fieldsToSend
        data.forEach((elt) => {
          Object.keys(elt).forEach((name) => {
            if (!fieldsToSend.includes(name)) {
              delete elt[name];
            }
            if (name == "Clearance date") {
              elt[name] = moment(elt[name]).format("DD/MM/YYYY");
            }
            if (name == "OfferID") {
              delete elt[name];
            }
            if (name == "Target value for 100% (other than OPRA/Buy back)") {
              let newName = "Target value for 100%, in M€ (other than OPRA/Buy back)";
              elt[newName] = isNaN(elt[name])
                ? elt[name]
                : Number(displayAmount(elt[name], 1000000, undefined, 2));
              if (!colToRemoveFromFields.find((elem) => elem.oldName === name))
                colToRemoveFromFields.push({ oldName: name, newName: newName });
              delete elt[name];
            }
            if (name == "Amount of the offer (Targeted securities only)") {
              let newName = "Amount of the offer, in k€ (Targeted securities only)";
              elt[newName] = isNaN(elt[name])
                ? elt[name]
                : Number(displayAmount(elt[name], 1000, undefined, 1));
              if (!colToRemoveFromFields.find((elem) => elem.oldName === name))
                colToRemoveFromFields.push({ oldName: name, newName: newName });
              delete elt[name];
            }
            if (name == "Bidder's costs" || name == "Independent expert's fees") {
              let newName = name + ", in k€";
              elt[newName] = isNaN(elt[name])
                ? elt[name]
                : Number(displayAmount(elt[name], 1000, undefined, 0));
              if (!colToRemoveFromFields.find((elem) => elem.oldName === name))
                colToRemoveFromFields.push({ oldName: name, newName: newName });
              delete elt[name];
            }
          });
        });

        // If we renamed some elements during our loop above, we need to replace the column name in the fieldsToSend
        // fieldsToSend determine the order of the col in the excel, that's why we rename our col at the index of the old one
        colToRemoveFromFields.forEach((elem) => {
          fieldsToSend[fieldsToSend.indexOf(elem.oldName)] = elem.newName;
        });

        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(data, {
          header: fieldsToSend.filter((elt) => elt !== "OfferID"),
        });

        var sheet2arr = function (ws) {
          var result = [];
          var row;
          var rowNum;
          var colNum;
          var range = XLSX.utils.decode_range(ws["!ref"]);
          for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
            row = [];
            for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
              var nextCell = ws[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
              if (typeof nextCell === "undefined") {
                row.push(" ");
              } else {
                const cellval = nextCell.v;
                if (typeof cellval == "string" && cellval.startsWith("iwl")) {
                  nextCell.l = { Target: nextCell.v, Tooltip: nextCell.v };
                }
                row.push(nextCell.v);
              }
            }
            result.push(row);
          }
          return result;
        };
        let test = sheet2arr(ws);

        XLSX.utils.book_append_sheet(wb, ws, "Tender Offers");
        var wsrows = [{ hpx: 60 }];
        ws["!rows"] = wsrows;

        var excel = XLSX.write(wb, { type: "binary", bookType: "xlsx", bookSST: false });
        var blob = new Blob([s2ab(excel)], { type: "" });

        let formData = new FormData();
        formData.append("filter", values.filter);
        formData.append("user", localStorage.getItem("username"));
        formData.append("referents", approval);
        formData.append("blob", blob, "tender_offers.xlsx");
        axios({
          method: "post",
          url: process.env.REACT_APP_BASE_OPA_BACKEND + "/email",
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
          validateStatus: (status) => {
            return true;
          },
        })
          .catch((error) => {
            console.log(error);
          })
          .then((response) => {
            console.log(response);
            var authtoken = localStorage.getItem("token");
            fetch(process.env.REACT_APP_BASE_OPA_BACKEND + "/session", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
              },
              body: JSON.stringify({
                sessionId: localStorage.getItem("sessionId"),
                export: true,
              }),
            })
              .catch((error) => {
                console.log(error);
              })
              .then((response) => {
                console.log(response);
              });
          });
      }
    };

    /* -----   Custom Action Buttons   ----- */
    const DealsActions = ({ currentSort, exporter, filterValues, resource, total }) => (
      <Toolbar className="actions-btn">
        <Button
          startIcon={<AddBoxOutlinedIcon color="primary" />}
          onClick={this.toggleDrawer}
          className="add-col-btn"
        >
          <span>ADD/DELETE COLUMN to the table</span>
        </Button>
        <Button
          startIcon={<GetAppIcon color="primary" />}
          onClick={this.toggleModal}
          className="add-col-btn"
        >
          EXPORT TABLE
        </Button>
        <Modal
          title="Merci de sélectionner les personnes (2 maximum) en charge de votre dossier"
          visible={this.state.modal}
          footer={[
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
              label="EXPORT TABLE"
              onClick={this.toggleModal}
            />,
          ]}
          onCancel={this.toggleModal}
        >
          <ListExport onSelectUsers={this.handleUsers} />
        </Modal>
      </Toolbar>
    );

    /* -----   Custom Pagination   ----- */
    const DealPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

    /* ----- Custom Title ----- */
    const TenderOfferTitle = () => {
      return (
        <div>
          <img src="Logo_BP_white.png" />
          <span className="dot"></span>
          <span className="tender-title">
            {" "}
            Base offres publiques – Cette base décrit toutes les offres depuis 2014 et tous les P2P
            depuis 2006
          </span>
        </div>
      );
    };

    return (
      <div>
        <List
          className="listoffers"
          {...props}
          title={<TenderOfferTitle />}
          exporter={exporter}
          actions={<DealsActions />}
          perPage={25}
          filters={<DealFilter filterwidth={this.props.windowWidth} />}
          bulkActionButtons={false}
          sort={{ field: "OfferID", order: "DESC" }}
          pagination={<DealPagination />}
        >
          <Datagrid className="alloffers" rowStyle={postRowStyle}>
            {ColToDisplay}
          </Datagrid>
        </List>
        <Drawer
          className="drawer"
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer}
        >
          <Typography variant="overline" className="instruction">
            Select columns you want to display
          </Typography>
          <AddColumns selectcol={this.state.col_list} onSelectCol={this.handleCol} />
        </Drawer>
      </div>
    );
  }
}

export default windowSize(DealList);
