import React from 'react';
import { Show, Datagrid, ReferenceArrayField, TabbedShowLayout, Tab, TextField, BooleanField, DateField, EditButton, RichTextField } from 'react-admin';

const UserTitle = ({ record }) => {
    return <span>{record.username}</span>;
};

export const UserShow = (props) => (
    <Show {...props} title={<UserTitle />}>
        <TabbedShowLayout>
            <Tab label="General Info">
                <TextField source="id" />
                <TextField source="username" />
                <TextField source="password" />
                <TextField source="role" />
                <BooleanField source="referent"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);