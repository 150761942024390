import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useLogin, useNotify } from 'react-admin';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Button as MuiButton } from '@material-ui/core';
import axios from "axios";
import { Modal, Button } from 'antd';


const MyLoginPage = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');
    const [visible, setVisible] = useState(false);
    const [visibleOtp, setVisibleOtp] = useState(false);

    const login = useLogin();
    const notify = useNotify();
    const submit = (e) => {
        e.preventDefault();
        login({ username, password }).catch(() => notify('Invalid email or password'));
        setTimeout(() => {
            if (localStorage.getItem("authtoken")) {
                setVisibleOtp(true);
            }
        }, 3000);
    };

    const checkOtp = (e) => {
        e.preventDefault();

        let authtoken = localStorage.getItem("authtoken");

        login({ authtoken, otp, username }).catch(() => notify('Invalid otp'));
        setTimeout(() => {
            if (localStorage.getItem("id")) {
                props.history.push('/tenderoffers')
            }
        }, 1500);
    };

    const getPassword = (e) => {
        e.preventDefault();
        setVisible(false)
        axios({
            method: 'post',
            url: process.env.REACT_APP_BASE_OPA_BACKEND + '/forgotpwd',
            data: { user: email },
            validateStatus: (status) => {
                return true;
            },
        }).then(response => {
            if (response.status === 404) {
                alert("We are sorry, the user with this email doesn't exist.")
            } else {
                alert("An email containing your pasword was sent to you.")
            }
            //setVisible(true)

            console.log(response);
        }).catch(error => {
            console.log(error);
        })
    }


    const showModal = () => {
        setVisible(true)
    };

    const handleCancel = e => {
        setVisible(false)
    };

    const showModalOtp = e => {
        setVisibleOtp(true)
    };

    const handleCancelOtp = e => {
        setVisibleOtp(false)
    };

    return (

        <div className="login-back">
            <Grid fluid>
                <Row>
                    <Col md={4} xsOffset={4} className="login-row">
                        <Card className="login-form">
                            <CardContent>
                                <img src="Logo_BP_V2.png" className="BP_logo" />
                                <form onSubmit={submit}>
                                    <Row>
                                        <Col md={12}>
                                            <TextField
                                                onChange={(e) => setUsername(e.target.value)}
                                                id="filled-email-input"
                                                label="Email"
                                                value={username}
                                                className="email"
                                                type="email"
                                                name="email"
                                                autoComplete="email"
                                                margin="normal"
                                                variant="filled"
                                                required
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <TextField
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                                id="filled-password-input"
                                                label="Password"
                                                className="pwd"
                                                type="password"
                                                autoComplete="current-password"
                                                margin="normal"
                                                variant="filled"
                                                required
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <MuiButton type="submit" className="login-btn">
                                                Login
                                                </MuiButton>
                                        </Col>
                                    </Row>
                                </form>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" className="forgot-pwd" onClick={showModal}>Forgot your password ?</Button>
                            </CardActions>
                        </Card>
                    </Col>
                </Row>
            </Grid>
            <Modal
                title="Forgot your password ?"
                visible={visible}
                footer={null}
                onCancel={handleCancel}
            >
                <p>Please enter your email : </p>
                <form onSubmit={getPassword}>
                    <TextField
                        id="filled-password-input"
                        label="Email"
                        type="email"
                        variant="filled"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                    />
                    <MuiButton type="submit" className="login-btn pwd-btn" >
                        Submit
                        </MuiButton>
                </form>
            </Modal>
            <Modal
                title="Merci de remplir le code à usage unique que vous venez de recevoir par mail"
                visible={visibleOtp}
                footer={null}
                onCancel={handleCancelOtp}
            >
                <p>Code à usage unique : </p>
                <form onSubmit={checkOtp}>
                    <TextField
                        id="filled-password-input"
                        label="Otp"
                        type="otp"
                        variant="filled"
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                        required
                    />
                    <MuiButton type="submit" className="login-btn pwd-btn">
                        Envoyer
                    </MuiButton>
                </form>
            </Modal>
        </div>
    );

};

export default withRouter(MyLoginPage);
