import React from 'react';
import { Toolbar, Create, SimpleForm, required, SelectInput, TextInput, BooleanInput, SaveButton } from 'react-admin';

const UserCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="SAVE"
            redirect="show"
            submitOnEnter={false}
        />
    </Toolbar>
);

export const UserCreate = props => (
    <Create   {...props}>
        <SimpleForm redirect="list" variant="standard" margin="normal" toolbar={<UserCreateToolbar />}>
            <TextInput source="username" label="username" type="email" validate={required()} resettable />
            <TextInput source="password" type="text" validate={required()} resettable />
            <SelectInput
                source="role"
                label="role"
                choices={[
                    { id: 'admin', name: 'admin' },
                    { id: 'normal', name: 'normal' },
                ]}
                validate={required()}
            />
            <BooleanInput label="Referent" source="referent" initialValue={false}/>
        </SimpleForm>
    </Create >
);