import React, { createElement } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import { withRouter } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  if (localStorage.getItem("role") == "admin") {
    return (
      <div className="menu-opa">
        {resources.map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
          />
        ))}
        <MenuItemLink
          key="4"
          to="/actions"
          primaryText="Actions"
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
        />
        {isXSmall}
      </div>
    );
  } else {
    return (
      <div>
        {resources.map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
          />
        ))}

        {isXSmall}
      </div>
    );
  }
};

export default withRouter(Menu);
