import React, { useMemo } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import HistoryIcon from '@material-ui/icons/History';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryWithStore } from 'react-admin';

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const TotalTime = (props) => {
    const classes = useStyles();
    const aMonthAgo = useMemo(() => {
        const date = new Date();
        date.setDate(date.getDate() - 30);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }, []);

    /*const { loaded, data: activitystat } = useQueryWithStore({
        type: 'getList',
        resource: 'activities',
        payload: {
            filter: {
                has_ordered: true,
                logindate_gte: aMonthAgo.toISOString(),
            },
            sort: { field: 'logindate', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    if (!loaded) return null;
    const nb = activitystat ? activitystat.reduce((a, b) => { if (b.timeSpent != null) { return (a + b.timeSpent) } else { return (a + 0) }; }, 0) : 0;*/
    var nb = 0
    for(var i = 0; i < props.activities.length; i++){
        if(props.activities[i].timeSpent !== undefined){
            
            nb = nb + Math.abs(props.activities[i].timeSpent)
        }
    }
    return (
        <div className={classes.main}>
            <CardIcon Icon={HistoryIcon} bgColor="#00ceaa" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    Time spent on the platform, hours
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {Math.round((nb/60) * 10)/10}
                </Typography>
            </Card>
        </div>
    );
};

export default TotalTime;