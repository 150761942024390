import React from 'react';
import { Edit, SimpleForm, required, SelectInput, TextInput, BooleanInput } from 'react-admin';

const UserTitle = ({ record }) => {
    return <span>{record["username"]}</span>;
};

export const UserEdit = props => (
    <Edit {...props} title={<UserTitle />}>
        <SimpleForm redirect="show" variant="standard" margin="normal">
            <TextInput disabled label="Id" source="id" />
            <TextInput source="username" label="username" type="email" validate={required()} resettable />
            <TextInput source="password" type="text" validate={required()} resettable />
            <SelectInput
                source="role"
                label="role"
                choices={[
                    { id: 'admin', name: 'admin' },
                    { id: 'normal', name: 'normal' },
                ]}
                validate={required()}
            />
            <BooleanInput label="Referent" source="referent" />
        </SimpleForm>
    </Edit >
);