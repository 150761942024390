import React from 'react';
import { Modal, Button } from 'antd';


export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, remainingTime }) => {

    return (
        <div>
            <Modal
                className="timeout"
                visible={showModal}
                title="Do you want to continue ?"
                footer={[
                    <Button key="danger" onClick={handleLogout}>
                        Logout
                    </Button>,
                    <Button key="primary" type="primary" onClick={handleClose}>
                        Stay
                    </Button>,

                ]}
            >
                <p>You have been inactive for 25 minutes.</p>
                <p>You will be disconnected in 5 minutes. Would you like to stay ?</p>
            </Modal>
        </div>
    )
}