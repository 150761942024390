import React, { useMemo } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ComputerIcon from '@material-ui/icons/Computer';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryWithStore } from 'react-admin';

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const TotalCo = (props) => {
    const classes = useStyles();
    const aMonthAgo = useMemo(() => {
        const date = new Date();
        date.setDate(date.getDate() - 30);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }, []);
    /*const { loaded, data: activitystat } = useQueryWithStore({
        type: 'getList',
        resource: 'activities',
        payload: {
            filter: {
                has_ordered: true,
                logindate_gte: aMonthAgo.toISOString(),
            },
            sort: { field: 'logindate', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    if (!loaded) return null;

    const nb = activitystat ? activitystat.reduce(nb => ++nb, 0) : 0;*/
    const nb = props.activities.length;
    return (
        <div className={classes.main}>
            <CardIcon Icon={ComputerIcon} bgColor="#ff8100" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    Total number of connexions
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {nb}
                </Typography>
            </Card>
        </div>
    );
};

export default TotalCo;