import React from "react";
import { Filter, CheckboxGroupInput, TextInput } from "react-admin";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, Row, Col } from "react-flexbox-grid";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Tooltip, Icon } from "antd";
import pure from "recompose/pure";

import { Tabs } from "antd";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

function DealFilter(props) {
  return (
    <div className="filters-panel">
      <Grid className="filters-grid" style={{ width: props.filterwidth - 63 }}>
        <Row start="xs">
          <Card className="top-filter">
            <Filter {...props} variant="standard" margin="normal">
              <TextInput label="FULL TEXT SEARCH" source="q" alwaysOn className="fullsearch" />
            </Filter>
            <Button
              className="clear-filters"
              startIcon={<HighlightOffIcon color="secondary" />}
              onClick={() =>
                props.setFilters({
                  "Alternext/Euronext": "",
                  "Pre-offer Period": "",
                  "Offer Type": "",
                  "Clearance Year": "",
                  Amount_gte: "",
                  "Offer Type": "",
                  "Target Type": "",
                  q: "",
                })
              }
            >
              Clear all fields
            </Button>
          </Card>
          <ExpansionPanel className="advanced-search" defaultExpanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <AddBoxOutlinedIcon className="adv-filter-icon" />
              <Typography className="advanced-search-p">ADVANCED SEARCH</Typography>
            </ExpansionPanelSummary>
            <Tabs defaultActiveKey="1" onChange={callback} animated={false}>
              <TabPane tab="GENERAL" key="1">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="big-subtitle">OFFER</div>
                      <Row>
                        <Col xs={12} md={6}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER  Offer type"
                              label="Offer type"
                              choices={[
                                {
                                  id: "Alternative offer",
                                  name: "Alternative offer",
                                },
                                { id: "Mixed offer", name: "Mixed offer" },
                                { id: "OPA", name: "OPA" },
                                { id: "OPAS", name: "OPAS" },
                                {
                                  id: "OPAS prior delisting",
                                  name: "OPAS prior delisting",
                                },
                                {
                                  id: "OPAS Share Buy Back",
                                  name: "OPAS Share Buy Back",
                                },
                                { id: "OPAS ≤ 10%", name: "OPAS ≤ 10%" },
                                { id: "OPE", name: "OPE" },
                                { id: "OPES", name: "OPES" },
                                { id: "OPR", name: "OPR" },
                                {
                                  id: "OPR - Art. 236-1",
                                  name: "OPR - Art. 236-1",
                                },
                                {
                                  id: "OPR - Art. 236-3",
                                  name: "OPR - Art. 236-3",
                                },
                                {
                                  id: "OPR - Art. 236-6",
                                  name: "OPR - Art. 236-6",
                                },
                                { id: "OPR-RO", name: "OPR-RO" },
                                { id: "OPRA > 10%", name: "OPRA > 10%" },
                                { id: "OPRA < 10%", name: "OPRA < 10%" },
                                { id: "RO", name: "RO" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={12} md={6}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="Mandatory/Voluntary"
                              label="Mandatory/Voluntary"
                              choices={[
                                { id: "Voluntary", name: "Voluntary" },
                                { id: "Mandatory", name: "Mandatory" },
                                {
                                  id: "Mandatory Art. 234-5",
                                  name: "Mandatory Art. 234-5",
                                },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="Friendly/Non-solicited"
                              label="Friendly/Non-solicited"
                              choices={[
                                { id: "Friendly", name: "Friendly" },
                                { id: "Non solicited", name: "Non solicited" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="Put up shut up"
                              label="Put up Shut up"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="Competing offer"
                              label="Competing offer"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="big-subtitle">BIDDER/TARGET</div>
                      <Row>
                        <Col xs={12} md={12}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="PE/VC Bidder -   Family office"
                              label="PE/VC Bidder Family office"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="Bidder acting in concert"
                              label="Bidder acting in concert"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="Target marketplace"
                              label="Target marketplace"
                              choices={[
                                { id: "Euronext", name: "Euronext" },
                                {
                                  id: "Euronext Growth",
                                  name: "Euronext Growth",
                                },
                                { id: "Alternext", name: "Alternext" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="big-subtitle">TIMETABLE</div>
                      <Row className="threshold">
                        <div className="width-title">
                          <span className="threshold-title">Clearance Year </span>
                          <Tooltip
                            className="title-legend"
                            title="Available in this database : all offers since 2015 and all P2P by PE funds since 2006"
                          >
                            <Icon type="info-circle" />
                          </Tooltip>
                        </div>
                        <Col xs={5} className="year">
                          <Filter {...props} variant="standard" margin="normal">
                            <TextInput source="FILTER Clearance year_gte" label="From" alwaysOn />
                          </Filter>
                        </Col>
                        <Col xs={5} xsOffset={2} className="year">
                          <Filter {...props} variant="standard" margin="normal">
                            <TextInput source="FILTER Clearance year_lte" label="To" alwaysOn />
                          </Filter>
                        </Col>
                      </Row>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Pre-offer"
                          label="Pre-offer"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="big-subtitle">OTHER</div>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Simultaneous offer abroad/on listed subsidiary of Target"
                          label="Simultaneous offer abroad/on listed subsidiary of Target"
                          choices={[
                            {
                              id: "Target shares: US registered offer",
                              name: "Target shares: US registered offer",
                            },
                            {
                              id: "Target shares: US offer exempted from registration",
                              name: "Target shares: US offer exempted from registration",
                            },
                            {
                              id: "Bidders' shares: US private placement",
                              name: "Bidders' shares: US private placement",
                            },
                            {
                              id: "Listed subsidiary of Target",
                              name: "Listed subsidiary of Target",
                            },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Litigation/Complaints from minority shareholders"
                          label="Litigation/Complaints from minority shareholders"
                          choices={[
                            { id: "Litigation", name: "Litigation" },
                            { id: "Complaints", name: "Complaints" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Deal Bredin Prat"
                          label="Deal Bredin Prat"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="BLOCK PURCHASE" key="2">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Block purchase (including contribution) %"
                          label="Block purchase (including contribution) %"
                          choices={[
                            {
                              id: "Between 0 and 10 %",
                              name: "Between 0 and 10 %",
                            },
                            {
                              id: "Between 10 and 20 %",
                              name: "Between 10 and 20 %",
                            },
                            {
                              id: "Between 20 and 30 %",
                              name: "Between 20 and 30 %",
                            },
                            {
                              id: "Between 30 and 40 %",
                              name: "Between 30 and 40 %",
                            },
                            {
                              id: "Between 40 and 50 %",
                              name: "Between 40 and 50 %",
                            },
                            { id: "Higher than 50%", name: "Higher than 50%" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Direct/Indirect block purchase"
                          label="Direct/Indirect block purchase"
                          choices={[
                            { id: "Direct", name: "Direct" },
                            {
                              id: "Direct and indirect",
                              name: "Direct and indirect",
                            },
                            { id: "Indirect", name: "Indirect" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Top up granted to block sellers"
                          label="Top up granted to block sellers"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Block price adjustment/Additional price"
                          label="Block price adjustment/ Additional price"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="AGREEMENTS IMPACTING THE OFFER" key="3">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER TOA"
                          label={
                            <div>
                              <span>TOA</span>
                              <Tooltip
                                className="title-legend"
                                title="Indicate whether TOA provides for a No Shop, Break-up Fee, Reverse break-up fee and R&W"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                      <Row className="threshold">
                        <div className="width-title">
                          <span className="threshold-title">% break-up fee /Deal value </span>
                          <Tooltip
                            className="title-legend"
                            title="Deal value = 100% of Target shares x offer price"
                          >
                            <Icon type="info-circle" />
                            {/*<span className="issue">
                              Max: ne pas dépasser 5%
                            </span>*/}
                          </Tooltip>
                        </div>
                        <Col xs={5} className="year">
                          <Filter {...props} variant="standard" margin="normal">
                            <TextInput
                              source="% break-up fee  /Deal value_gte"
                              label="Min"
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={5} xsOffset={2} className="year">
                          <Filter {...props} variant="standard" margin="normal">
                            <TextInput
                              source="% break-up fee  /Deal value_lte"
                              label="Max"
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                      <Row className="threshold">
                        <div className="width-title">
                          <span className="threshold-title">
                            % reverse break-up fee /Deal value
                          </span>
                          <Tooltip
                            className="title-legend"
                            title="Deal value = 100% of Target shares x offer price"
                          >
                            <Icon type="info-circle" />
                          </Tooltip>
                        </div>
                        <Col xs={5} className="year">
                          <Filter {...props} variant="standard" margin="normal">
                            <TextInput
                              source="% reverse break-up fee  /Deal value_gte"
                              label="Min"
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={5} xsOffset={2} className="year">
                          <Filter {...props} variant="standard" margin="normal">
                            <TextInput
                              source="% reverse break-up fee  /Deal value_lte"
                              label="Max"
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={2}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Undertaking to tender"
                          label="Undertaking to tender"
                          choices={[
                            { id: "< 10%", name: "< 10%" },
                            {
                              id: "< 10% irrevocable",
                              name: "< 10% irrevocable",
                            },
                            {
                              id: "Between 10% and 20%",
                              name: "Between 10% and 20%",
                            },
                            {
                              id: "Between 20% and 30%",
                              name: "Between 20% and 30%",
                            },
                            {
                              id: "Between 30% and 40%",
                              name: "Between 30% and 40%",
                            },
                            {
                              id: "Between 40% and 50%",
                              name: "Between 40% and 50%",
                            },
                            { id: "> 50%", name: "> 50%" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Undertaking not to tender"
                          label="Undertaking not to tender"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={2}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Reinvestment"
                          label="Reinvestment"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Management package  (other than reinvestment)"
                          label={
                            <div>
                              <span>Management package (other than reinvestment)</span>
                              <Tooltip
                                className="title-legend"
                                title="Describe Man Pack and other advantages granted to managers in the context of the offer"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={2}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Escrow of Target shares"
                          label="Escrow of Target shares"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Other agreements"
                          label={
                            <div>
                              <span>Other agreements</span>
                              <Tooltip
                                className="title-legend"
                                title="Other agreements (SHA, call option…) that may have an impact on the offer"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Liquidity agreements"
                          label="Liquidity agreements"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                            { id: "Contemplated", name: "Contemplated" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Liquidity - Fixed or variable price"
                          label="Liquidity - Fixed or variable price"
                          choices={[
                            { id: "Fixed price", name: "Fixed price" },
                            { id: "Variable price", name: "Variable price" },
                            {
                              id: "Fixed or variable price",
                              name: "Fixed or variable price",
                            },
                            { id: "Not indicated", name: "Not indicated" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="OFFER PRICE - DEAL VALUE" key="4">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="big-subtitle">OFFER PRICE</div>
                      <Row>
                        <Col xs={12} md={6}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Top up granted to minority shareholders"
                              label="Top up granted to minority shareholders"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Offer price adjustment/Additional offer price"
                              label="Offer price adjustment/ Additional offer price"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Squeeze out kicker - %/Offer price"
                              label="Squeeze out kicker - %/Offer price"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Brokerage fees"
                              label="Brokerage fees"
                              choices={[
                                { id: "Reimbursement", name: "Reimbursement" },
                                {
                                  id: "No reimbursement",
                                  name: "No reimbursement",
                                },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={12} md={6}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="Offer price/Exchange ratio increase prior to clearance"
                              label={
                                <div>
                                  <span>
                                    Offer price/Exchange ratio increase prior to clearance
                                  </span>
                                  <Tooltip
                                    className="title-legend"
                                    title="Indicate whether the offer price has been increased between pre-offer/filing and clearance"
                                  >
                                    <Icon type="info-circle" />
                                  </Tooltip>
                                </div>
                              }
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              row={false}
                              source="FILTER  Offer price increase / Surenchère"
                              label="Offer price increase / Surenchère"
                              choices={[
                                { id: "Yes automatic", name: "Yes automatic" },
                                { id: "Yes voluntary", name: "Yes voluntary" },
                                {
                                  id: "Yes automatic and voluntary",
                                  name: "Yes automatic and voluntary",
                                },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Offer price excluding or including the dividend"
                              label="Offer price excluding or including the dividend"
                              choices={[
                                {
                                  id: "Price excluding the dividend",
                                  name: "Price excluding the dividend",
                                },
                                {
                                  id: "Price including the dividend",
                                  name: "Price including the dividend",
                                },
                                {
                                  id: "Price excluding and including the dividend",
                                  name: "Price excluding and including the dividend",
                                },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="big-subtitle">DEAL VALUE</div>
                      <Row>
                        <Col xs={12} md={6}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col col-name"
                              source="FILTRE Target value for 100% (other than OPRA/Buy back)"
                              label={"Target value for 100% \r\n (other than OPRA/Buy back)"}
                              choices={[
                                { id: "< 100M €", name: "< 100M €" },
                                {
                                  id: "100M € - 250M€",
                                  name: "100M € - 250M€",
                                },
                                { id: "250M€ - 500M€", name: "250M€ - 500M€" },
                                { id: "500M€ - 1B€", name: "500M€ - 1B€" },
                                { id: "> 1B€", name: "> 1B€" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={12} md={6}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col col-name"
                              source="FILTER Amount of the offer (Targeted securities only)"
                              label={"Amount of the offer \r\n (Targeted securities only)"}
                              choices={[
                                { id: "< 100M €", name: "< 100M €" },
                                {
                                  id: "100M € - 250M€",
                                  name: "100M € - 250M€",
                                },
                                { id: "250M€ - 500M€", name: "250M€ - 500M€" },
                                { id: "500M€ - 1B€", name: "500M€ - 1B€" },
                                { id: "> 1B€", name: "> 1B€" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="PREMIUM - BP - DATAROOM" key="5">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="big-subtitle">PREMIUM</div>
                      {/*<span className="issue">
                        Ces critères de recherche sont en cours de maintenance
                        et momentanément indisponibles. Contactez Nathalie si
                        besoin.
                            </span>*/}
                      <Row>
                        <Col xs={12} md={6}>
                          <Row className="threshold">
                            <span className="threshold-title">SPOT</span>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="SPOT_gte" label="Min" alwaysOn />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="SPOT_lte" label="Max" alwaysOn />
                              </Filter>
                            </Col>
                          </Row>
                          <Row className="threshold">
                            <span className="threshold-title">1M/20 j</span>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="1M/20 j_gte" label="Min" alwaysOn />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="1M/20 j_lte" label="Max" alwaysOn />
                              </Filter>
                            </Col>
                          </Row>
                          <Row className="threshold">
                            <span className="threshold-title">2M</span>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="2M_gte" label="Min" alwaysOn />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="2M_lte" label="Max" alwaysOn />
                              </Filter>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={6}>
                          <Row className="threshold">
                            <span className="threshold-title">3M/60 j</span>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="3M/60 j_gte" label="Min" alwaysOn />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="3M/60 j_lte" label="Max" alwaysOn />
                              </Filter>
                            </Col>
                          </Row>
                          <Row className="threshold">
                            <span className="threshold-title">6M</span>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="6M_gte" label="Min" alwaysOn />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="6M_lte" label="Max" alwaysOn />
                              </Filter>
                            </Col>
                          </Row>
                          <Row className="threshold">
                            <span className="threshold-title">1Y</span>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="1Y_gte" label="Min" alwaysOn />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput source="1Y_lte" label="Max" alwaysOn />
                              </Filter>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="big-subtitle">BP - DATAROOM</div>
                      <Row>
                        <Col xs={12} md={5} mdOffset={1}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER  BP or other documentation available to Bidder"
                              label="BP or other documentation available to Bidder"
                              choices={[
                                { id: "Target BP", name: "Target BP" },
                                { id: "Bidder BP", name: "Bidder BP" },
                                {
                                  id: "Target BP and Bidder BP",
                                  name: "Target BP and Bidder BP",
                                },
                                {
                                  id: "Analyst consensus",
                                  name: "Analyst consensus",
                                },
                                { id: "Other", name: "Other" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER  BP or other documentation available to the expert"
                              label="BP or other documentation available to the expert"
                              choices={[
                                { id: "Target BP", name: "Target BP" },
                                { id: "Bidder BP", name: "Bidder BP" },
                                {
                                  id: "Target BP and Bidder BP",
                                  name: "Target BP and Bidder BP",
                                },
                                {
                                  id: "Analyst consensus",
                                  name: "Analyst consensus",
                                },
                                { id: "Other", name: "Other" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={12} md={5}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Data room/Inside information"
                              label={
                                <div>
                                  <span>Data room/Inside information</span>
                                  <Tooltip
                                    className="title-legend"
                                    title="Access to data room and inside information (other than Target BP)"
                                  >
                                    <Icon type="info-circle" />
                                  </Tooltip>
                                </div>
                              }
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="PRE-OFFER CONDITIONS" key="6">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="big-subtitle">REGULATORY PRE-OFFER CONDITIONS</div>
                      <Row>
                        <Col xs={12} md={6}>
                          <div className="subtitle2">Condition precedent to the block purchase</div>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer CP to block purchase - Antitrust"
                              label="Antitrust"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer CP to block purchase - Foreign investment"
                              label="Foreign investment"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer CP to block purchase - Other regulatory approval"
                              label="Other regulatory approval"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="subtitle2">
                            Condition precedent to the filing of the offer
                          </div>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer CP to filing  - Antitrust"
                              label="Antitrust"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer CP to filing - Foreign investment"
                              label="Foreign investment"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer CP to filing - Other regulatory conditions"
                              label="Other regulatory conditions"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="big-subtitle">OTHER PRE-OFFER CONDITIONS</div>
                      <Row>
                        <Col xs={12} md={6}>
                          <div className="subtitle2">Condition precedent to the block purchase</div>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer CP to block purchase - Target recommending the offer/Fairness opinion"
                              label="Target recommending the offer/Fairness opinion"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer Other CP to block purchase"
                              label="Other condition precedent to the filing of the offer"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="subtitle2">
                            Condition precedent to the filing of the offer
                          </div>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer CP to filing - Target recommending the offer/Fairness opinion"
                              label="Target recommending the offer/Fairness opinion"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Pre-offer Other CP to filing of the offer"
                              label="Other"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="OFFER CONDITIONS" key="7">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Opening of the offer subject to regulatory approval"
                          label={
                            <div>
                              <span>Opening of the offer subject to regulatory approval</span>
                              <Tooltip
                                className="title-legend"
                                title="Indicate whether the opening of the offer is subject to the receipt by the AMF of the required regulatory approval (RG AMF art. 231-32, 3°)"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes CFIUS", name: "Yes CFIUS" },
                            { id: "Yes Other", name: "Yes Other" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Mandatory level of acceptances Seuil de caducité - Art. 231-9 I Only applicable since 30 June 2014"
                          label={
                            <div>
                              <span>
                                Mandatory level of acceptances <br />
                                Seuil de caducité - Art. 231-9 I
                              </span>
                              <Tooltip
                                className="title-legend"
                                title="Only applicable since 30 June 2014"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "Exemption", name: "Exemption" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col col-name"
                          source="FILTER   Optional level of acceptances Seuil de renonciation - Art. 231-9 II"
                          label={
                            "Optional level of acceptances \r\n Seuil de renonciation - Art. 231-9 II"
                          }
                          choices={[
                            { id: "> 50%", name: "> 50%" },
                            { id: "> 65%", name: "> 65%" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Other offer conditions"
                          label={
                            <div>
                              <span>Other offer conditions</span>
                              <Tooltip
                                className="title-legend"
                                title="Other offer conditions as authorized by RG AMF"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="SECURITIES TARGETED/EXCLUDED" key="8">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={2}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Free shares non vested"
                          label="Free shares non vested"
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Free shares subject to lock-up"
                          label="Free shares subject to lock-up"
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Free shares subject to liquidity agreement"
                          label="Free shares subject to liquidity agreement"
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={2}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Shares likely to be issued"
                          label={
                            <div>
                              <span>Shares likely to be issued</span>
                              <Tooltip
                                className="title-legend"
                                title="Shares likely to be issued upon exercise of convertible bonds, stock options or other"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                            {
                              id: "Targeted and excluded",
                              name: "Targeted and excluded",
                            },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Preferred shares"
                          label="Preferred shares"
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Non listed securities"
                          label="Non listed securities"
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Warrants"
                          label={
                            <div>
                              <span>Warrants</span>
                              <Tooltip className="title-legend" title="BSA/BSAAR/ BAAR or similar">
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Targeted bonds giving access to Target capital"
                          label="Targeted bonds giving access to Target capital"
                          choices={[
                            { id: "OC", name: "OC" },
                            { id: "OCEANE", name: "OCEANE" },
                            { id: "ORNANE", name: "ORNANE" },
                            { id: "OSRA", name: "OSRA" },
                            {
                              id: "Other bonds giving access to capital",
                              name: "Other bonds giving access to capital",
                            },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={2}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Treasury shares"
                          label="Treasury shares"
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                            {
                              id: "Targeted and excluded",
                              name: "Targeted and excluded",
                            },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Other securities"
                          label="Other securities"
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                            {
                              id: "Targeted and excluded",
                              name: "Targeted and excluded",
                            },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Shares owned by PEE/FCPE"
                          label="Shares owned by PEE/FCPE"
                          choices={[
                            { id: "Excluded", name: "Excluded" },
                            { id: "Targeted", name: "Targeted" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Excluded shares underlying put/call options"
                          label="Excluded shares underlying put/call options"
                          choices={[{ id: "Excluded", name: "Excluded" }]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Excluded securities subject to an undertaking not to tender"
                          label="Excluded securities subject to an undertaking not to tender"
                          choices={[{ id: "Excluded", name: "Excluded" }]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Excluded securities owned by executives"
                          label="Excluded securities owned by executives"
                          choices={[{ id: "Excluded", name: "Excluded" }]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="BIDDER INTENTIONS" key="9">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER  Squeeze out"
                          label={
                            <div>
                              <span>Squeeze out</span>
                              <Tooltip
                                className="title-legend"
                                title="Intention to initiate a squeeze out"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Delisting"
                          label={
                            <div>
                              <span>Delisting</span>
                              <Tooltip
                                className="title-legend"
                                title="Intention to request delisting of Target"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                            {
                              id: "Reserve the right",
                              name: "Reserve the right",
                            },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Merger"
                          label={
                            <div>
                              <span>Merger</span>
                              <Tooltip className="title-legend" title="Intention to merge Target">
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                            {
                              id: "Reserve the right ",
                              name: "Reserve the right ",
                            },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Subsequent OPR-RO"
                          label={
                            <div>
                              <span>Subsequent OPR-RO</span>
                              <Tooltip
                                className="title-legend"
                                title="Intention to file an OPR-RO if the squeeze out threshold is reached subsequently"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Exceptional dividend distribution/  Debt push down"
                          label={
                            <div>
                              <span>Exceptional dividend distribution/ Debt push down</span>
                              <Tooltip
                                className="title-legend"
                                title="Intention to make an exceptional dividend distribution or a debt push down"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                            {
                              id: "Reserve the right",
                              name: "Reserve the right",
                            },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Other intentions"
                          label={
                            <div>
                              <span>Other intentions</span>
                              <Tooltip
                                className="title-legend"
                                title="Indicate other relevant intentions"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="New strategy"
                          label={
                            <div>
                              <span>New strategy</span>
                              <Tooltip
                                className="title-legend"
                                title="If Bidder holds 50% of Target or more, it must disclose any new strategy (if any) - Applicable since 10 February 2020"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Disclosure of synergies' amount"
                          label={
                            <div>
                              <span>Disclosure of synergies' amount</span>
                              <Tooltip
                                className="title-legend"
                                title="Indicate whether the amount of the synergies is disclosed"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="BIDDER'S STAKE - RESULTS - SQUEEZE OUT" key="10">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={3}>
                      <Row>
                        {/*<span className="issue">
                          Ces critères de recherche sont en cours de maintenance
                          et momentanément indisponibles. Contactez Nathalie si
                          besoin.
                        </span>*/}
                        <Col xs={12} md={12}>
                          <Row className="threshold">
                            <div className="width-title">
                              <span className="threshold-title">Initial % owned by Bidder</span>
                              <Tooltip className="title-legend" title=" % of Target share capital">
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput
                                  source="FILTER  Initial % owned by Bidder_gte"
                                  label="Min"
                                  alwaysOn
                                />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput
                                  source="FILTER  Initial % owned by Bidder_lte"
                                  label="Max"
                                  alwaysOn
                                />
                              </Filter>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Row className="threshold">
                            <div className="width-title">
                              <span className="threshold-title">At filing - % owned by Bidder</span>
                              <Tooltip className="title-legend" title="% of Target share capital">
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput
                                  source="FILTER  At filing - % owned by  Bidder_gte"
                                  label="Min"
                                  alwaysOn
                                />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput
                                  source="FILTER  At filing - % owned by  Bidder_lte"
                                  label="Max"
                                  alwaysOn
                                />
                              </Filter>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Row className="threshold">
                            <div className="width-title">
                              <span className="threshold-title">
                                End of the offer % owned by Bidder
                              </span>
                              <Tooltip
                                className="title-legend"
                                title="% of Target share capital or % of targeted shares for  OPRA"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                            <Col xs={5} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput
                                  source="FILTER  End of the offer % owned by  Bidder_gte"
                                  label="Min"
                                  alwaysOn
                                />
                              </Filter>
                            </Col>
                            <Col xs={5} xsOffset={2} className="year">
                              <Filter {...props} variant="standard" margin="normal">
                                <TextInput
                                  source="FILTER  End of the offer % owned by  Bidder_lte"
                                  label="Max"
                                  alwaysOn
                                />
                              </Filter>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Squeeze out following the offer"
                          label="Squeeze out following the offer"
                          choices={[
                            { id: "Yes in cash", name: "Yes in cash" },
                            {
                              id: "Yes in cash or shares",
                              name: "Yes in cash or shares",
                            },
                            { id: "Failed", name: "Failed" },
                          ]}
                          alwaysOn
                        />
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTRE  Green mailing"
                          label={
                            <div>
                              <span>Green mailing</span>
                              <Tooltip
                                className="title-legend"
                                title="Green mailing preventing to reach the squeeze out threshold"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Maybe", name: "Maybe" },
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="Squeeze out following a subsequent offer"
                          label="Squeeze out following a subsequent offer"
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                    <Col xs={12} md={3}>
                      <Filter {...props} variant="standard" margin="normal">
                        <CheckboxGroupInput
                          className="checkbox-col"
                          source="FILTER Market purchase"
                          label={
                            <div>
                              <span>Market purchase</span>
                              <Tooltip
                                className="title-legend"
                                title="Market purchases during the offer period"
                              >
                                <Icon type="info-circle" />
                              </Tooltip>
                            </div>
                          }
                          choices={[
                            { id: "Yes", name: "Yes" },
                            { id: "No", name: "No" },
                          ]}
                          alwaysOn
                        />
                      </Filter>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
              <TabPane tab="FAIRNESS OPINION - TARGET RECOMMENDATION" key="11">
                <Grid className="filter-container">
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="big-subtitle">FAIRNESS OPINION</div>
                      <Row>
                        <Col xs={12} md={6}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Fairness opinion"
                              label="Fairness opinion"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                          <Row>
                            <Col xs={12} md={6}>
                              <Filter {...props} variant="standard" margin="normal">
                                <CheckboxGroupInput
                                  className="checkbox-col"
                                  source="FILTER Art. 261-1, I, 1°"
                                  label="Art. 261-1, I, 1°"
                                  choices={[{ id: "Yes", name: "Yes" }]}
                                  alwaysOn
                                />
                                <CheckboxGroupInput
                                  className="checkbox-col"
                                  source="FILTER Art. 261-1, I, 3°"
                                  label="Art. 261-1, I, 3°"
                                  choices={[{ id: "Yes", name: "Yes" }]}
                                  alwaysOn
                                />
                                <CheckboxGroupInput
                                  className="checkbox-col"
                                  source="FILTER Art. 261-1, I, 5°"
                                  label="Art. 261-1, I, 5°"
                                  choices={[{ id: "Yes", name: "Yes" }]}
                                  alwaysOn
                                />
                                <CheckboxGroupInput
                                  className="checkbox-col"
                                  source="FILTER Art. 261-3"
                                  label="Art. 261-3"
                                  choices={[{ id: "Yes", name: "Yes" }]}
                                  alwaysOn
                                />
                              </Filter>
                            </Col>
                            <Col xs={12} md={6}>
                              <Filter {...props} variant="standard" margin="normal">
                                <CheckboxGroupInput
                                  className="checkbox-col"
                                  source="FILTER Art. 261-1, I, 2°"
                                  label="Art. 261-1, I, 2°"
                                  choices={[{ id: "Yes", name: "Yes" }]}
                                  alwaysOn
                                />
                                <CheckboxGroupInput
                                  className="checkbox-col"
                                  source="FILTER Art. 261-1, I, 4°"
                                  label="Art. 261-1, I, 4°"
                                  choices={[{ id: "Yes", name: "Yes" }]}
                                  alwaysOn
                                />
                                <CheckboxGroupInput
                                  className="checkbox-col"
                                  source="FILTER Art. 261-1, I, 6°"
                                  label="Art. 261-1, I, 6°"
                                  choices={[{ id: "Yes", name: "Yes" }]}
                                  alwaysOn
                                />
                                <CheckboxGroupInput
                                  className="checkbox-col"
                                  source="FILTER Art. 261-1, II"
                                  label="Art. 261-1, II"
                                  choices={[{ id: "Yes", name: "Yes" }]}
                                  alwaysOn
                                />
                              </Filter>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={6}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="AMF request for appointing a new expert Art. 261-1-1 II  Applicable since 10 February 2020"
                              label={
                                <div>
                                  <span>
                                    AMF request for appointing a new expert Art. 261-1-1 II
                                  </span>
                                  <Tooltip
                                    className="title-legend"
                                    title="Applicable since 10 February 2020. AMF's request to appoint a new independent expert to issue a new fairness opinion when the initial expert's report was insufficient"
                                  >
                                    <Icon type="info-circle" />
                                  </Tooltip>
                                </div>
                              }
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="AMF objection to the appointment of the expert Art. 261-1-1 III Applicable since 10 February 2020"
                              label={
                                <div>
                                  <span>
                                    AMF objection to the appointment of the expert Art. 261-1-1 III
                                  </span>
                                  <Tooltip
                                    className="title-legend"
                                    title="Applicable since 10 February 2020. AMF objection to the appointment of an independent expert proposed by Target if no ad hoc committee is set up or  upon AMF's request"
                                  >
                                    <Icon type="info-circle" />
                                  </Tooltip>
                                </div>
                              }
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} md={6}>
                      <div className="big-subtitle">TARGET BOARD REVIEW OF THE OFFER</div>
                      <Row>
                        <Col xs={12} md={4}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Ad hoc committee"
                              label="Ad hoc committee"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="Conflicted directors"
                              label={
                                <div>
                                  <span>Conflicted directors</span>
                                  <Tooltip
                                    className="title-legend"
                                    title="If disclosed as such in the offer document"
                                  >
                                    <Icon type="info-circle" />
                                  </Tooltip>
                                </div>
                              }
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER  Directors' abstention if conflicted"
                              label={
                                <div>
                                  <span>Directors' abstention if conflicted</span>
                                  <Tooltip
                                    className="title-legend"
                                    title="Abstention from the disclosed conflicted directors"
                                  >
                                    <Icon type="info-circle" />
                                  </Tooltip>
                                </div>
                              }
                              choices={[
                                { id: "Yes", name: "Yes" },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={12} md={4}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER  Target recommendation"
                              label="Target recommendation"
                              choices={[
                                { id: "Unanimous", name: "Unanimous" },
                                { id: "Majority", name: "Majority" },
                                {
                                  id: "Not recommended",
                                  name: "Not recommended",
                                },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER Board attendance"
                              label="Board attendance"
                              choices={[
                                {
                                  id: "All board members present or represented",
                                  name: "All board members present or represented",
                                },
                                {
                                  id: "Less than all board members were present or represented",
                                  name: "Less than all board members were present or represented",
                                },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                        <Col xs={12} md={4}>
                          <Filter {...props} variant="standard" margin="normal">
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER  Tender of treasury shares"
                              label="Tender of treasury shares"
                              choices={[
                                { id: "Yes", name: "Yes" },
                                {
                                  id: "Yes, but not all",
                                  name: "Yes, but not all",
                                },
                                { id: "No", name: "No" },
                              ]}
                              alwaysOn
                            />
                            <CheckboxGroupInput
                              className="checkbox-col"
                              source="FILTER  Workers council opinion"
                              label={
                                <div>
                                  <span>Workers council opinion</span>
                                  <Tooltip
                                    className="title-legend"
                                    title="Opinion of the workers council on the offer (Information available since June 2018 only)"
                                  >
                                    <Icon type="info-circle" />
                                  </Tooltip>
                                </div>
                              }
                              choices={[
                                { id: "Favorable", name: "Favorable" },
                                { id: "Negative", name: "Negative" },
                                { id: "Other", name: "Other" },
                              ]}
                              alwaysOn
                            />
                          </Filter>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
              </TabPane>
            </Tabs>
          </ExpansionPanel>
        </Row>
      </Grid>
    </div>
  );
}

export default pure(DealFilter);
