import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Upload, Button } from "antd";
import axios from "axios";

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      files: [],
      busy: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({ busy: true });
    const token = localStorage.getItem("token");

    fetch(process.env.REACT_APP_BASE_OPA_BACKEND + "/tenderoffers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then(async (obj) => {
        for (let index = 0; index < obj.body.length; index++) {
          const element = obj.body[index];
          await fetch(process.env.REACT_APP_BASE_OPA_BACKEND + "/tenderoffers/" + element.id, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
        }

        if (this.state.files.length > 1) {
          alert("You are only allowed to submit one file.");
        } else {
          if (this.state.file !== {}) {
            var formData = new FormData();
            formData.append("file", this.state.files[0]);
            axios({
              url: process.env.REACT_APP_BASE_OPA_BACKEND + "/submit",
              method: "post",
              processData: false,
              data: formData,
            })
              .then(async (response) => {
                var result = response.data["Tender Offers"];

                for (let index = 0; index < result.length; index++) {
                  const elt = result[index];
                  if (elt.id != "") {
                    Object.keys(elt).forEach(function (keyname) {
                      var newKey = keyname
                        .replace(/(?:\\[rn]|[\r\n]+)+/g, " ")
                        .replace(/^\s+/g, "")
                        .replace(/\s*$/, "");
                      //var newKey = keyname.replace(/^\s+/g, '').replace(/\s*$/, "");
                      if (newKey != keyname) {
                        Object.defineProperty(
                          elt,
                          newKey,
                          Object.getOwnPropertyDescriptor(elt, keyname)
                        );
                        delete elt[keyname];
                      }
                    });
                    // var numid = parseFloat(elt.ID);
                    elt.id = index + 1;

                    var percentdata = [
                      "SPOT",
                      "1M/20 j",
                      "2M",
                      "3M/60 j",
                      "6M",
                      "1Y",
                      "% break-up fee  /Deal value",
                      "% reverse break-up fee  /Deal value",
                      "FILTER  At filing - % owned by  Bidder",
                      "FILTER  End of the offer % owned by  Bidder",
                      "FILTER  Initial % owned by Bidder",
                      "% of Bidder's costs/Target value for 100%",
                    ];

                    percentdata.forEach(function (col) {
                      if (typeof elt[col] == "string" && elt[col].includes("%") == true) {
                        elt[col] = parseFloat(parseFloat(elt[col].slice(0, -1)).toFixed(2));
                      } else {
                        if (typeof elt[col] == "number") {
                          elt[col] = parseFloat((elt[col] * 100).toFixed(2));
                        }
                      }
                    });

                    await fetch(process.env.REACT_APP_BASE_OPA_BACKEND + "/tenderoffers", {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                      body: JSON.stringify(elt),
                    })
                      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
                      .then((obj) => {});
                  }
                }
                this.setState({ busy: false });
                alert("The database was successfully updated.");
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert("Please submit a file");
          }
        }
      });
  }

  render() {
    const { uploading, files, file } = this.state;
    const props = {
      multiple: false,
      accept:
        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files.indexOf(file);
          const newFileList = state.files.slice();
          newFileList.splice(index, 1);
          return {
            files: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          files: [...state.files, file],
        }));
        return false;
      },
      files,
    };

    //request role
    if (localStorage.getItem("role") == "admin") {
      var permission = (
        <Card className="card-height">
          <CardHeader title="File upload" />
          <CardContent>
            <Upload {...props} className="upload-file" fileList={this.state.files}>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Upload>
          </CardContent>
          <CardActions className="card-actions">
            <Button
              type="submit"
              onClick={this.onSubmit}
              disabled={files.length === 0 || this.state.busy}
              loading={uploading || this.state.busy}
              style={{ marginTop: 16 }}
              loadingPosition="start"
              variant="outlined"
            >
              {uploading ? "Uploading" : "Start Upload"}
            </Button>
          </CardActions>
        </Card>
      );
    }

    return (
      <Grid fluid>
        <Row>
          <Col xs={12} md={5}>
            {permission}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Home;
