import React from "react";

import NbrUsers from "./NbrUsers";
import TotalCo from "./TotalCo";
import TotalTime from "./TotalTime";
import { Grid, Row, Col } from "react-flexbox-grid";
import DataTable, { createTheme } from "react-data-table-component";

class StatList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      activities: [],
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_BASE_OPA_BACKEND + "/users", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .catch((error) => {
        console.log(error);
      })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ users: data });
      });
    fetch(process.env.REACT_APP_BASE_OPA_BACKEND + "/getActivities", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .catch((error) => {
        console.log(error);
      })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ activities: data });
      });
  }

  render() {
    const { ...props } = this.props;

    const Recap = () => (
      <Grid fluid className="recap-grid">
        <Row>
          <Col xs={12}>
            <Row center="xs">
              <Col xs={12} md={3}>
                <NbrUsers users={this.state.users} />
              </Col>
              <Col xs={12} md={3}>
                <TotalCo activities={this.state.activities} />
              </Col>
              <Col xs={12} md={3}>
                <TotalTime activities={this.state.activities} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );

    const ActivitiesTitle = () => {
      return (
        <div>
          {/*<span>Tender Offers </span>*/}
          <img src="Logo_BP_white.png" />
          <span className="dot"></span>
          {/*<span className="tender-title"> The view that allows you to access all the public offerings, filter through them and export the resulting table.</span>*/}
          <span className="tender-title"> Activities</span>
        </div>
      );
    };

    const columns = [
      {
        name: "Session Id",
        selector: (row) => row.id,
        sortable: true,
        width: "140px",
      },
      {
        name: "Username",
        selector: (row) => row.username,
        sortable: true,
        width: "350px",
      },
      {
        name: "Date & Time of connexion",
        selector: (row) => row.logindate,
        sortable: true,
        width: "250px",
      },
      {
        name: "Time spent (min)",
        selector: (row) => row.timeSpent,
        sortable: true,
      },
      {
        name: "Export",
        selector: (row) => row.export,
        sortable: true,
        sortFunction: (rowA, rowB) => {
          let x, y;
          x = rowA.export ? 1 : 0;
          y = rowB.export ? 1 : 0;
          return x - y;
        },
        format: (row) => (row.export ? "true" : ""),
      },
    ];

    createTheme("bp", {
      text: {
        primary: "black",
      },
      background: {
        default: "white",
      },
      striped: {
        default: "#ecf2f8",
      },
    });

    return (
      <>
        <Recap />
        <DataTable
          columns={columns}
          data={this.state.activities}
          pagination
          paginationPerPage={25}
          dense
          striped
          theme="bp"
          defaultSortFieldId={1}
          defaultSortAsc={false}
        />
      </>
    );
  }
}

export default StatList;
