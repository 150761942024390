import React from "react";
import { Show, TabbedShowLayoutTabs, TabbedShowLayout, Tab } from "react-admin";
import { Grid, Row, Col } from "react-flexbox-grid";
import Moment from "react-moment";
import windowSize from "react-window-size";
import currencyFormatter from "currency-formatter";
import GetAppIcon from "@material-ui/icons/GetApp";

const DealTitle = ({ record }) => {
  return <span>{record["Target Offer type Year"]}</span>;
};

// Careful, not exactly the same function as in Listdeals.js (no check on unit param being provided...)
const displayAmount = (nb, div, unit, fixed) => {
  if (isNaN(nb)) return nb;
  const numberFormat = new Intl.NumberFormat("fr-FR");
  var finalNb = nb / div;
  if (nb >= div / 10) finalNb = finalNb.toFixed(fixed);
  return numberFormat.format(finalNb) + "\xa0" + unit;
};

const General = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={2}>
          <p className="col-elt">TARGET</p>
          <h3 className="record-title">Target</h3>
          <p>{record["Target"] ? record["Target"] : "N/A"}</p>
          <h3 className="record-title">Target marketplace</h3>
          <p>{record["Target marketplace"] ? record["Target marketplace"] : "N/A"}</p>
          <h3 className="record-title">Business sector</h3>
          <p>{record["Business sector"] ? record["Business sector"] : "N/A"}</p>
        </Col>
        <Col xs={12} md={3}>
          <p className="col-elt">BIDDER</p>
          <h3 className="record-title">Bidder</h3>
          <p>{record["Bidder"] ? record["Bidder"] : "N/A"}</p>
          <h3 className="record-title">Bidder acting in concert</h3>
          <p>{record["Bidder acting in concert"] ? record["Bidder acting in concert"] : "N/A"}</p>
          <h3 className="record-title">Controlling shareholders of Bidder</h3>
          <p>
            {record["Controlling shareholders of Bidder"]
              ? record["Controlling shareholders of Bidder"]
              : "N/A"}
          </p>
          <h3 className="record-title">PE/VC Bidder - Family office</h3>
          <p>
            {record["PE/VC Bidder -   Family office"]
              ? record["PE/VC Bidder -   Family office"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={2}>
          <p className="col-elt">OFFER</p>
          <h3 className="record-title">Offer type</h3>
          <p>{record["Offer type"] ? record["Offer type"] : "N/A"}</p>
          <h3 className="record-title">Competing offer</h3>
          <p>{record["Competing offer"] ? record["Competing offer"] : "N/A"}</p>
          <h3 className="record-title">Mandatory/Voluntary</h3>
          <p>{record["Mandatory/Voluntary"] ? record["Mandatory/Voluntary"] : "N/A"}</p>
          <h3 className="record-title">Put up shut up</h3>
          <p>{record["Put up shut up"] ? record["Put up shut up"] : "N/A"}</p>
          <h3 className="record-title">Friendly/Non-solicited</h3>
          <p>{record["Friendly/Non-solicited"] ? record["Friendly/Non-solicited"] : "N/A"}</p>
          <h3 className="record-title">Simultaneous offer abroad/on listed subsidiary of Target</h3>
          <p>
            {record["Simultaneous offer abroad/on listed subsidiary of Target"]
              ? record["Simultaneous offer abroad/on listed subsidiary of Target"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={2}>
          <p className="col-elt">TIMETABLE</p>
          <h3 className="record-title">Pre-offer</h3>
          <p>{record["Pre-offer"] ? record["Pre-offer"] : "N/A"}</p>
          <h3 className="record-title">Filing date</h3>
          <p>
            <Moment format="DD/MM/YYYY">{record["Filing date"]}</Moment>
          </p>
          <h3 className="record-title">Clearance date</h3>
          <p>
            <Moment format="DD/MM/YYYY">{record["Clearance date"]}</Moment>
          </p>
        </Col>

        <Col xs={12} md={3}>
          <p className="col-elt">OTHER</p>
          <h3 className="record-title">Litigation/Complaints from minority shareholders</h3>
          <p>
            {record["Litigation/Complaints  from minority shareholders"]
              ? record["Litigation/Complaints  from minority shareholders"]
              : "N/A"}
          </p>
          <h3 className="record-title">Deal Bredin Prat</h3>
          <p>{record["Deal Bredin Prat"] ? record["Deal Bredin Prat"] : "N/A"}</p>
          <h3 className="record-title">Comments</h3>
          <p>{record["Comments"] ? record["Comments"] : "N/A"}</p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const BlockPurchase = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={6}>
          <h3 className="record-title" style={{ whiteSpace: "pre-line" }}>
            Block purchase (including contribution of Target shares) {"\r\n"} % acquired
          </h3>
          <p>
            {record["Block purchase (including contribution of Target shares) % acquired"]
              ? record["Block purchase (including contribution of Target shares) % acquired"]
              : "N/A"}
          </p>
          <h3 className="record-title">Direct/Indirect block purchase</h3>
          <p>
            {record["Direct/Indirect block purchase"]
              ? record["Direct/Indirect block purchase"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={6}>
          <h3 className="record-title">Top up granted to block sellers</h3>
          <p>
            {record["Top up granted to block sellers"]
              ? record["Top up granted to block sellers"]
              : "N/A"}
          </p>
          <h3 className="record-title">Block price adjustment/Additional price</h3>
          <p>
            {record["Block price adjustment/Additional price"]
              ? record["Block price adjustment/Additional price"]
              : "N/A"}
          </p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const OtherAgreements = ({ record }) => (
  <div>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={3}>
          <h3 className="record-title">TOA</h3>
          <p>{record["TOA"] ? record["TOA"] : "N/A"}</p>
          <h3 className="record-title">% break-up fee /Deal value</h3>
          <p>
            {record["% break-up fee  /Deal value"]
              ? record["% break-up fee  /Deal value"] + "%"
              : "N/A"}
          </p>
          <h3 className="record-title">% reverse break-up fee /Deal value</h3>
          <p>
            {record["% reverse break-up fee  /Deal value"]
              ? record["% reverse break-up fee  /Deal value"] + "%"
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={3}>
          <h3 className="record-title">Undertaking to tender/not to tender</h3>
          <p>
            {record["Undertaking to tender/not to tender"]
              ? record["Undertaking to tender/not to tender"]
              : "N/A"}
          </p>
          <h3 className="record-title">Escrow of Target shares</h3>
          <p>{record["Escrow of Target shares"] ? record["Escrow of Target shares"] : "N/A"}</p>
        </Col>
        <Col xs={12} md={3}>
          <h3 className="record-title">Reinvestment</h3>
          <p>{record["Reinvestment"] ? record["Reinvestment"] : "N/A"}</p>
          <h3 className="record-title" style={{ whiteSpace: "pre-line" }}>
            Management package {"\r\n"} (other than reinvestment)
          </h3>
          <p>
            {record["Management package (other than reinvestment)"]
              ? record["Management package (other than reinvestment)"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={3}>
          <h3 className="record-title">Liquidity agreements Fixed/Variable price</h3>
          <p>
            {record["Liquidity agreements Fixed/Variable price"]
              ? record["Liquidity agreements Fixed/Variable price"]
              : "N/A"}
          </p>
          <h3 className="record-title">Other agreements</h3>
          <p>{record["Other agreements"] ? record["Other agreements"] : "N/A"}</p>
        </Col>
      </Row>
    </Grid>
  </div>
);

const OfferPriceDealValue = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={4}>
          <p className="col-elt">OFFER PRICE</p>
          <h3 className="record-title">Top up granted to minority shareholders</h3>
          <p>
            {record["Top up granted to minority shareholders"]
              ? record["Top up granted to minority shareholders"]
              : "N/A"}
          </p>
          <h3 className="record-title">Offer price adjustment/ Additional offer price</h3>
          <p>
            {record["Offer price adjustment/Additional offer price"]
              ? record["Offer price adjustment/Additional offer price"]
              : "N/A"}
          </p>
          <h3 className="record-title">Squeeze out kicker - %/Offer price</h3>
          <p>
            {record["Squeeze out kicker - %/Offer price"]
              ? record["Squeeze out kicker - %/Offer price"]
              : "N/A"}
          </p>
          <h3 className="record-title">Reimbursement of brokerage fees</h3>
          <p>
            {record["Reimbursement of brokerage fees"]
              ? record["Reimbursement of brokerage fees"]
              : "N/A"}
          </p>
          <h3 className="record-title">Offer price/Exchange ratio increase prior to clearance</h3>
          <p>
            {record["Offer price/Exchange ratio increase prior to clearance"]
              ? record["Offer price/Exchange ratio increase prior to clearance"]
              : "N/A"}
          </p>
          <h3 className="record-title">Offer price excluding or including the dividend</h3>
          <p>
            {record["Offer price excluding or including the dividend"]
              ? record["Offer price excluding or including the dividend"]
              : "N/A"}
          </p>
          <h3 className="record-title">Offer price increase/Surenchère %</h3>
          <p>
            {record["Offer price increase/Surenchère %"]
              ? record["Offer price increase/Surenchère %"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <p className="col-elt">DEAL VALUE</p>
          <h3 className="record-title">Target value for 100%, in M€ (other than OPRA/Buy back)</h3>
          <p>
            {record["Target value for 100% (other than OPRA/Buy back)"]
              ? displayAmount(
                  record["Target value for 100% (other than OPRA/Buy back)"],
                  1000000,
                  "M€",
                  2
                )
              : "N/A"}
          </p>
          <h3 className="record-title">Amount of the offer, in k€ (Targeted securities only)</h3>
          <p>
            {record["Amount of the offer (Targeted securities only)"]
              ? displayAmount(
                  record["Amount of the offer (Targeted securities only)"],
                  1000,
                  "k€",
                  1
                )
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <p className="col-elt">BIDDER'S COST</p>
          <h3 className="record-title">Bidder's costs, in k€</h3>
          <p>
            {record["Bidder's costs"]
              ? displayAmount(record["Bidder's costs"], 1000, "k€", 0)
              : "N/A"}
          </p>
          <h3 className="record-title">Cost of the block purchase included in Bidder's costs ?</h3>
          <p>
            {record["Cost of the block purchase included in Bidder's costs ?"]
              ? record["Cost of the block purchase included in Bidder's costs ?"]
              : "N/A"}
          </p>
          <h3 className="record-title">% of Bidder's costs/Target value for 100%</h3>
          <p>
            {record["% of Bidder's costs/Target value for 100%"]
              ? record["% of Bidder's costs/Target value for 100%"] + "%"
              : "N/A"}
          </p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const PremiumDataroom = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={4}>
          <p className="col-elt">PREMIUM</p>
          <h3 className="record-title">SPOT</h3>
          <p>{record["SPOT"] || record["SPOT"] === 0 ? record["SPOT"] + "%" : "N/A"}</p>
          <h3 className="record-title">1M/20 j</h3>
          <p>{record["1M/20 j"] || record["1M/20 j"] === 0 ? record["1M/20 j"] + "%" : "N/A"}</p>
          <h3 className="record-title">2M</h3>
          <p>{record["2M"] || record["2M"] === 0 ? record["2M"] + "%" : "N/A"}</p>
          <h3 className="record-title">3M/60 j</h3>
          <p>{record["3M/60 j"] || record["3M/60 j"] === 0 ? record["3M/60 j"] + "%" : "N/A"}</p>
          <h3 className="record-title">6M</h3>
          <p>{record["6M"] || record["6M"] === 0 ? record["6M"] + "%" : "N/A"}</p>
          <h3 className="record-title">1Y</h3>
          <p>{record["1Y"] || record["1Y"] === 0 ? record["1Y"] + "%" : "N/A"}</p>
          <h3 className="record-title">Comments relating to premium (if necessary)</h3>
          <p>
            {record["Comments relating to premium  (if necessary)"]
              ? record["Comments relating to premium  (if necessary)"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <p className="col-elt">INFO AVAILABLE</p>
          <h3 className="record-title">Data room/Inside information</h3>
          <p>
            {record["Data room/Inside information"]
              ? record["Data room/Inside information"]
              : "N/A"}
          </p>
          <h3 className="record-title">BP or other documentation available to Bidder and expert</h3>
          <p>
            {record["BP or other documentation available to Bidder and expert"]
              ? record["BP or other documentation available to Bidder and expert"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <p className="col-elt">MULTICRITERIA ANALYSIS</p>
          <h3 className="record-title">Net Asset Value</h3>
          <p>{record["Net Asset Value"] ? record["Net Asset Value"] : "N/A"}</p>
          <h3 className="record-title">Revalued Net Asset Value</h3>
          <p>{record["Revalued Net Asset Value"] ? record["Revalued Net Asset Value"] : "N/A"}</p>
          <h3 className="record-title">Trading prices</h3>
          <p>{record["Trading prices"] ? record["Trading prices"] : "N/A"}</p>
          <h3 className="record-title">Financial analysts price targets</h3>
          <p>
            {record["Financial analysts price targets"]
              ? record["Financial analysts price targets"]
              : "N/A"}
          </p>
          <h3 className="record-title">DCF</h3>
          <p>{record["DCF"] ? record["DCF"] : "N/A"}</p>
          <h3 className="record-title">Dividend discount model</h3>
          <p>{record["Dividend discount model"] ? record["Dividend discount model"] : "N/A"}</p>
          <h3 className="record-title">Multiples of comparable companies</h3>
          <p>
            {record["Multiples of comparable companies"]
              ? record["Multiples of comparable companies"]
              : "N/A"}
          </p>
          <h3 className="record-title">Precedent transactions</h3>
          <p>{record["Precedent transactions"] ? record["Precedent transactions"] : "N/A"}</p>
          <h3 className="record-title">Recent transaction</h3>
          <p>{record["Recent transaction"] ? record["Recent transaction"] : "N/A"}</p>
          <h3 className="record-title">Other criteria</h3>
          <p>{record["Other criteria"] ? record["Other criteria"] : "N/A"}</p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const Conditions = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={4}>
          <h3 className="record-title">Pre-offer Regulatory condition precedent</h3>
          <p>
            {record["Pre-offer Regulatory condition precedent"]
              ? record["Pre-offer Regulatory condition precedent"]
              : "N/A"}
          </p>
          <h3 className="record-title">Pre-offer Other condition precedent</h3>
          <p>
            {record["Pre-offer Other condition precedent"]
              ? record["Pre-offer Other condition precedent"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <h3 className="record-title">Opening of the offer subject to regulatory approval</h3>
          <p>
            {record["Opening of the offer subject to regulatory approval"]
              ? record["Opening of the offer subject to regulatory approval"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <h3 className="record-title" style={{ whiteSpace: "pre-line" }}>
            Mandatory level of acceptances {"\r\n"} Only applicable since 30 June 2014
          </h3>
          <p>
            {record["Mandatory level of acceptances Only applicable since 30 June 2014"]
              ? record["Mandatory level of acceptances Only applicable since 30 June 2014"]
              : "N/A"}
          </p>
          <h3 className="record-title">Optional level of acceptances</h3>
          <p>
            {record["Optional level of acceptances"]
              ? record["Optional level of acceptances"]
              : "N/A"}
          </p>
          <h3 className="record-title">Other offer conditions</h3>
          <p>{record["Other offer conditions"] ? record["Other offer conditions"] : "N/A"}</p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const SecuritiesTargeted = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={6}>
          <h3 className="record-title">Securities targeted</h3>
          <p>{record["Securities targeted"] ? record["Securities targeted"] : "N/A"}</p>
          <h3 className="record-title">Securities excluded</h3>
          <p>{record["Securities excluded"] ? record["Securities excluded"] : "N/A"}</p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const BidderIntention = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={6}>
          <h3 className="record-title">Squeeze out</h3>
          <p>{record["Squeeze out"] ? record["Squeeze out"] : "N/A"}</p>
          <h3 className="record-title">Merger</h3>
          <p>{record["Merger"] ? record["Merger"] : "N/A"}</p>
          <h3 className="record-title">Delisting</h3>
          <p>{record["Delisting"] ? record["Delisting"] : "N/A"}</p>
          <h3 className="record-title">Subsequent OPR-RO</h3>
          <p>{record["Subsequent OPR-RO"] ? record["Subsequent OPR-RO"] : "N/A"}</p>
        </Col>
        <Col xs={12} md={6}>
          <h3 className="record-title">Exceptional dividend distribution/ Debt push down</h3>
          <p>
            {record["Exceptional dividend distribution/  Debt push down"]
              ? record["Exceptional dividend distribution/  Debt push down"]
              : "N/A"}
          </p>
          <h3 className="record-title">Disclosure of synergies' amount</h3>
          <p>
            {record["Disclosure of synergies' amount"]
              ? record["Disclosure of synergies' amount"]
              : "N/A"}
          </p>
          <h3 className="record-title">New strategy</h3>
          <p>{record["New strategy"] ? record["New strategy"] : "N/A"}</p>
          <h3 className="record-title">Other relevant intentions</h3>
          <p>{record["Other relevant intentions"] ? record["Other relevant intentions"] : "N/A"}</p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const BidderStakeResultsSqueezeOut = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={6}>
          <h3 className="record-title">Initial Bidder's stake</h3>
          <p>
            {record["Initial Bidder's stake C = share capital  VR = voting rights"] ||
              (record["Initial Bidder's stake C = share capital  VR = voting rights"] === 0
                ? record["Initial Bidder's stake C = share capital  VR = voting rights"] + "%"
                : "N/A")}
          </p>
          <h3 className="record-title" style={{ whiteSpace: "pre-line" }}>
            At filing {"\r\n"} % owned by Bidder
          </h3>
          <p>
            {record["At filing % owned by Bidder C = share capital  VR = voting rights"] ||
              (record["At filing % owned by Bidder C = share capital  VR = voting rights"] === 0
                ? record["At filing % owned by Bidder C = share capital  VR = voting rights"] + "%"
                : "N/A")}
          </p>
          <h3 className="record-title" style={{ whiteSpace: "pre-line" }}>
            Before reopening of the offer {"\r\n"} % owned by Bidder
          </h3>
          <p>
            {record[
              "Before reopening of the offer % owned by Bidder C = share capital  VR = voting rights"
            ] ||
              (record[
                "Before reopening of the offer % owned by Bidder C = share capital  VR = voting rights"
              ] === 0
                ? record[
                    "Before reopening of the offer % owned by Bidder C = share capital  VR = voting rights"
                  ] + "%"
                : "N/A")}
          </p>
          <h3 className="record-title" style={{ whiteSpace: "pre-line" }}>
            End of the offer{"\r\n"} % owned by Bidder{" "}
          </h3>
          <p>
            {record["End of the offer  % owned by  Bidder C = share capital  VR = voting rights"] ||
              (record[
                "End of the offer  % owned by  Bidder C = share capital  VR = voting rights"
              ] === 0
                ? record[
                    "End of the offer  % owned by  Bidder C = share capital  VR = voting rights"
                  ] + "%"
                : "N/A")}
          </p>
        </Col>
        <Col xs={12} md={6}>
          <h3 className="record-title">Squeeze out following the offer</h3>
          <p>
            {record["Squeeze out following the offer"]
              ? record["Squeeze out following the offer"]
              : "N/A"}
          </p>
          <h3 className="record-title">Market purchase %</h3>
          <p>{record["Market purchase %"] ? record["Market purchase %"] : "N/A"}</p>
          <h3 className="record-title">Squeeze out threshold calculation</h3>
          <p>
            {record["Squeeze out threshold calculation"]
              ? record["Squeeze out threshold calculation"]
              : "N/A"}
          </p>
          <h3 className="record-title">Squeeze out following a subsequent offer</h3>
          <p>
            {record["Squeeze out following a subsequent offer"]
              ? record["Squeeze out following a subsequent offer"]
              : "N/A"}
          </p>
          <h3 className="record-title">Green mailing</h3>
          <p>{record["Green mailing"] ? record["Green mailing"] : "N/A"}</p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const FairnessOpinionTargetRecommendation = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={4}>
          <h3 className="record-title">Fairness opinion</h3>
          <p>{record["Fairness opinion"] ? record["Fairness opinion"] : "N/A"}</p>
          <h3 className="record-title">AMF request for appointing a new expert Art. 261-1-1 II</h3>
          <p>
            {record["AMF request for appointing a new expert Art. 261-1-1 II"]
              ? record["AMF request for appointing a new expert Art. 261-1-1 II"]
              : "N/A"}
          </p>
          <h3 className="record-title">
            AMF objection to the appointment of the expert Art. 261-1-1 III
          </h3>
          <p>
            {record["AMF objection to the appointment of the expert Art. 261-1-1 III"]
              ? record["AMF objection to the appointment of the expert Art. 261-1-1 III"]
              : "N/A"}
          </p>
          <h3 className="record-title">Independent expert</h3>
          <p>{record["Independent expert"] ? record["Independent expert"] : "N/A"}</p>
          <h3 className="record-title">Independent expert's fees, in k€</h3>
          <p>
            {record["Independent expert's fees"]
              ? displayAmount(record["Independent expert's fees"], 1000, "k€", 0)
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <h3 className="record-title">Ad hoc committee Mission</h3>
          <p>{record["Ad hoc committee Mission"] ? record["Ad hoc committee Mission"] : "N/A"}</p>
          <h3 className="record-title">Conflicted directors</h3>
          <p>{record["Conflicted directors"] ? record["Conflicted directors"] : "N/A"}</p>
          <h3 className="record-title">Directors' abstention if conflicted</h3>
          <p>
            {record["Directors' abstention if conflicted"]
              ? record["Directors' abstention if conflicted"]
              : "N/A"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <h3 className="record-title">Board attendance</h3>
          <p>{record["Board attendance"] ? record["Board attendance"] : "N/A"}</p>
          <h3 className="record-title">Target recommendation</h3>
          <p>{record["Target recommendation"] ? record["Target recommendation"] : "N/A"}</p>
          <h3 className="record-title">Tender of treasury shares</h3>
          <p>{record["Tender of treasury shares"] ? record["Tender of treasury shares"] : "N/A"}</p>
          <h3 className="record-title">Workers council opinion</h3>
          <p>{record["Workers council opinion"] ? record["Workers council opinion"] : "N/A"}</p>
        </Col>
      </Row>
    </Grid>
  </span>
);

const LinksToAMF = ({ record }) => (
  <span>
    <Grid className="offer-grid" style={{ width: "100%" }}>
      <Row>
        <Col xs={12} md={6}>
          <h3 className="record-title">Conformité AMF</h3>
          {record["Conformité AMF"] != undefined && record["Conformité AMF"].startsWith("iwl") ? (
            <p className="link-p">
              <span>Open document</span>
              <a className="link" href={record["Conformité AMF"]} target="_blank">
                <GetAppIcon />
              </a>
            </p>
          ) : record["Conformité AMF"] ? (
            <p>{record["Conformité AMF"]}</p>
          ) : (
            <p> </p>
          )}
          <h3 className="record-title">Note d'information</h3>
          {record["Note d'information"] != undefined &&
          record["Note d'information"].startsWith("iwl") ? (
            <p className="link-p">
              <span>Open document</span>
              <a className="link" href={record["Note d'information"]} target="_blank">
                <GetAppIcon />
              </a>
            </p>
          ) : record["Note d'information"] ? (
            <p>{record["Note d'information"]}</p>
          ) : (
            <p> </p>
          )}
          <h3 className="record-title">Note en réponse</h3>
          {record["Note en réponse"] != undefined && record["Note en réponse"].startsWith("iwl") ? (
            <p className="link-p">
              <span>Open document</span>
              <a className="link" href={record["Note en réponse"]} target="_blank">
                <GetAppIcon />
              </a>
            </p>
          ) : record["Note en réponse"] ? (
            <p>{record["Note en réponse"]}</p>
          ) : (
            <p> </p>
          )}
          <h3 className="record-title">Mise en œuvre du RO</h3>
          {record["Mise en œuvre du RO"] != undefined &&
          record["Mise en œuvre du RO"].startsWith("iwl") ? (
            <p className="link-p">
              <span>Open document</span>
              <a className="link" href={record["Mise en œuvre du RO"]} target="_blank">
                <GetAppIcon />
              </a>
            </p>
          ) : record["Mise en œuvre du RO"] ? (
            <p>{record["Mise en œuvre du RO"]}</p>
          ) : (
            <p> </p>
          )}
          <h3 className="record-title">Complément note d'information 1 (surenchère)</h3>
          {record["Complément note d'information 1 (surenchère)"] != undefined &&
          record["Complément note d'information 1 (surenchère)"].startsWith("iwl") ? (
            <p className="link-p">
              <span>Open document</span>
              <a
                className="link"
                href={record["Complément note d'information 1 (surenchère)"]}
                target="_blank"
              >
                <GetAppIcon />
              </a>
            </p>
          ) : record["Complément note d'information 1 (surenchère)"] ? (
            <p>{record["Complément note d'information 1 (surenchère)"]}</p>
          ) : (
            <p> </p>
          )}
          <h3 className="record-title">Complément note d'information (surenchère)</h3>
          {record["Complément note d'information (surenchère)"] != undefined &&
          record["Complément note d'information (surenchère)"].startsWith("iwl") ? (
            <p className="link-p">
              <span>Open document</span>
              <a
                className="link"
                href={record["Complément note d'information (surenchère)"]}
                target="_blank"
              >
                <GetAppIcon />
              </a>
            </p>
          ) : record["Complément note d'information (surenchère)"] ? (
            <p>{record["Complément note d'information (surenchère)"]}</p>
          ) : (
            <p> </p>
          )}
        </Col>
      </Row>
    </Grid>
  </span>
);

const DealShow = (props) => (
  <Grid className="offer-tab" style={{ width: props.windowWidth - 63 }}>
    <Show {...props} title={<DealTitle />}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons={"on"} {...props} />}
        className="tabs-offer"
      >
        <Tab label="GENERAL">
          <General />
        </Tab>
        <Tab label="BLOCK PURCHASE">
          <BlockPurchase />
        </Tab>
        <Tab label="AGREEMENTS IMPACTING THE OFFER">
          <OtherAgreements />
        </Tab>
        <Tab label="OFFER PRICE - DEAL VALUE - COST">
          <OfferPriceDealValue />
        </Tab>
        <Tab label="PREMIUM - MULTICRITERIA ANALYSIS - BP - DATAROOM">
          <PremiumDataroom />
        </Tab>
        <Tab label="PRE-OFFER CONDITIONS - OFFER CONDITIONS">
          <Conditions />
        </Tab>
        <Tab label="SECURITIES TARGETED/EXCLUDED">
          <SecuritiesTargeted />
        </Tab>
        <Tab label="BIDDER'S INTENTIONS">
          <BidderIntention />
        </Tab>
        <Tab label="BIDDER'S STAKE - RESULTS - SQUEEZE OUT">
          <BidderStakeResultsSqueezeOut />
        </Tab>
        <Tab label="FAIRNESS OPINION - TARGET RECOMMENDATION">
          <FairnessOpinionTargetRecommendation />
        </Tab>
        <Tab label="LINK TO AMF DOCUMENTS">
          <LinksToAMF />
        </Tab>
      </TabbedShowLayout>
    </Show>
  </Grid>
);

export default windowSize(DealShow);
