import React from 'react';
import { List, Datagrid, TextField, Pagination, Filter, TextInput, BooleanField } from 'react-admin';

const postRowStyle = (record, index) => ({
    backgroundColor: index % 2 == 0 ? '#ecf2f8' : 'white',
});

const UserTitle = () => {
    return (
        <div>
            <img src="Logo_BP_white.png"/>
            <span className="dot"></span>
            <span className="tender-title"> Users</span>
        </div>);
};

const UserFilter = (props) => (
    <Filter {...props} variant="standard" margin="normal">
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

//const UsersPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const UserList = props => (
    <List  {...props} title={<UserTitle />} filters={<UserFilter />} exporter={false} sort={{ field: 'id', order: 'ASC' }} perPage={25}>
        <Datagrid rowClick="show" rowStyle={postRowStyle} className="users">
            <TextField source="id" />
            <TextField source="username"/>
            <TextField source="role" />
            <BooleanField source="referent" />
        </Datagrid>
    </List>
);