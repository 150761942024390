import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';
import decodeJwt from 'jwt-decode';
import axios from 'axios';

function getDate() {
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    return [dateTime, (today.getHours()*60) + today.getMinutes()];
}

var loginMin;

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        if (params.username && params.password) {
            const {username, password} = params;
            axios({
                method: 'post',
                url: process.env.REACT_APP_BASE_OPA_BACKEND + '/login',
                data: {username, password},
                validateStatus: (status) => {
                    return true;
                }
            }).catch(error => {
                console.log(error);
            }).then(res => {
                var authtoken = res.data.token;

                if (res.status === 200) {
                    var authtoken = res.data.token;
                    localStorage.setItem('authtoken', authtoken);
                    return Promise.resolve();
                } else {
                    localStorage.removeItem("authtoken");
                    return Promise.reject(alert('Invalid username or password'));
                }

            });

        } else if (params.authtoken, params.otp) {

            var login = getDate();
            var loginDate = login[0];
            loginMin = login[1];

            axios(process.env.REACT_APP_BASE_OPA_BACKEND + '/session',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${params.authtoken}`
                    },
                    data: {
                        userId: decodeJwt(params.authtoken).id,
                        logindate: loginDate,
                        loginMin: loginMin,
                        username: params.username,
                        authcode: params.otp
                    },
                    validateStatus: (status) => {
                        return true;
                    }
                }
            ).catch(error => {
                console.log(error);
            }).then(res => {
                if (res.data.id) {
                    localStorage.setItem('sessionId', res.data.id);
                }

                if (res.status < 200 || res.status >= 300) {
                    return Promise.reject(alert('Invalid OTP code'));
                }

                const decodedToken = decodeJwt(params.authtoken);
                localStorage.setItem('token', params.authtoken);
                localStorage.setItem('username', decodedToken.username);
                localStorage.setItem('id', decodedToken.id);
                localStorage.setItem('role', decodedToken.role);
                localStorage.removeItem("authtoken");

            });

        }
    }
    if (type === AUTH_LOGOUT) {
        var logoutdate = getDate();
        var token = localStorage.getItem('token');
       fetch(process.env.REACT_APP_BASE_OPA_BACKEND + '/session', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({sessionId: localStorage.getItem('sessionId'), logoutTime: logoutdate})
        }).catch(error => {
            console.log(error);
          })
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        localStorage.removeItem('id');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('token');
        return Promise.resolve();
        
    }
    if (type === AUTH_ERROR) {
        // ...
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('username') ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        var token = localStorage.getItem('token');
        const decodedToken = decodeJwt(token);
        const role = decodedToken.role;
        return role ? Promise.resolve(role) : Promise.reject();
    }
    return Promise.reject();
};
