import React, { useMemo } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-admin';

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const NbrUsers = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.main}>
            <CardIcon Icon={PeopleIcon} bgColor="#9610eb" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    Total number of users
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {props.users.length}
                </Typography>
            </Card>
        </div>
    );
};

export default NbrUsers;