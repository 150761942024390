
import React from "react";
import { Checkbox } from "antd";

export class ListExport extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            users: []
        };
    }

    onChange = checkedValues => {
        this.props.onSelectUsers(checkedValues);
        this.setState(() => {
            return { checked: checkedValues };
        });
    };

    isDisabled = id => {
        return (
            this.state.checked.length > 1 && this.state.checked.indexOf(id) === -1
        );
    };

    componentWillMount() {
        const token = localStorage.getItem('token');
        fetch(process.env.REACT_APP_BASE_OPA_BACKEND + '/users', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).catch(error => {
            console.log(error);
        }).then(response => response.json())
            .then(data => {
                this.setState({ users: data })
            });
    }

    render() {
        return (
            <Checkbox.Group style={{ width: "100%" }} onChange={this.onChange}>
                {this.state.users.map((user) => {
                    if (user.referent == true) {
                        return (
                            <div className="select-referent" key={user.username}>
                                <Checkbox value={user.username} disabled={this.isDisabled(user.username)}>
                                    {user.username}
                                </Checkbox>
                                <br />
                            </div>
                        )
                    }
                })}
            </Checkbox.Group>
        )
    }
}

export default ListExport;
