import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';


export class AddColumns extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            right: false,
        };
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange = (elt) => {
        this.props.onSelectCol(elt);
    }

    render() {
        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >GENERAL</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={2}>
                                            <p className="col-elt">TARGET</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "GENERAL" && (elt.name === "Target" || elt.name === "Target marketplace" || elt.name === "Business sector")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <p className="col-elt">BIDDER</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "GENERAL" && (elt.name === "Bidder" || elt.name === "Bidder acting in concert" || elt.name === "Controlling shareholders of Bidder" || elt.name === "PE/VC Bidder -   Family office")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <p className="col-elt">OFFER</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "GENERAL" && (elt.name === "Offer type" || elt.name === "Competing offer" || elt.name === "Mandatory/Voluntary" || elt.name === "Put up shut up" || elt.name === "Friendly/Non-solicited" || elt.name === "Simultaneous offer abroad/on listed subsidiary of Target")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <p className="col-elt">TIMETABLE</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "GENERAL" && (elt.name === "Pre-offer" || elt.name === "Filing date" || elt.name === "Clearance date")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <p className="col-elt">OTHER</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "GENERAL" && (elt.name === "Litigation/Complaints  from minority shareholders" || elt.name === "Deal Bredin Prat" || elt.name === "Comments")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }

                                            })
                                            }
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "GENERAL" && elt.name === "OfferID") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >BLOCK PURCHASE</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "BLOCK PURCHASE" && elt.name === "Block purchase (including contribution of Target shares) % acquired") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText className="col-name" primary={`Block purchase (including contribution of Target shares) \r\n % acquired`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "BLOCK PURCHASE" && elt.name === "Direct/Indirect block purchase") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "BLOCK PURCHASE" && (elt.name === "Top up granted to block sellers" || elt.name === "Block price adjustment/Additional price")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >AGREEMENTS IMPACTING THE OFFER</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={3}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "AGREEMENTS IMPACTING THE OFFER" && (elt.name === "TOA" || elt.name === "% break-up fee  /Deal value" || elt.name === "% reverse break-up fee  /Deal value")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={3}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "AGREEMENTS IMPACTING THE OFFER" && (elt.name === "Undertaking to tender/not to tender" || elt.name === "Escrow of Target shares")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={3}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "AGREEMENTS IMPACTING THE OFFER" && elt.name === "Reinvestment") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "AGREEMENTS IMPACTING THE OFFER" && elt.name === "Management package (other than reinvestment)") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText className="col-name" primary={`Management package \r\n (other than reinvestment)`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={3}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "AGREEMENTS IMPACTING THE OFFER" && (elt.name === "Other agreements" || elt.name === "Liquidity agreements Fixed/Variable price")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >OFFER PRICE - DEAL VALUE - COST</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <p className="col-elt">OFFER PRICE</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "OFFER PRICE") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <p className="col-elt">DEAL VALUE</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "DEAL VALUE") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <p className="col-elt">BIDDER'S COST</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "COST") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >PREMIUM - MULTICRITERIA ANALYSIS - BP - DATAROOM</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <p className="col-elt">PREMIUM</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "PREMIUM") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <p className="col-elt">INFO AVAILABLE</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "BP - DATAROOM") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <p className="col-elt">MULTICRITERIA ANALYSIS</p>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "MULTICRITERIA") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >PRE-OFFER CONDITIONS - OFFER CONDITIONS</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "PRE-OFFER CONDITIONS") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={4}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "OFFER CONDITIONS" && elt.name === "Opening of the offer subject to regulatory approval") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={4}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "OFFER CONDITIONS" && elt.name != "Opening of the offer subject to regulatory approval") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText className="col-name" primary={elt.name === "Mandatory level of acceptances Only applicable since 1st April 2014" ? "Mandatory level of acceptances \n Only applicable since 1st April 2014" : `${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >SECURITIES TARGETED/EXCLUDED</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={6}>
                                    {this.props.selectcol.map((elt, i) => {
                                        if (elt.family_name === "SECURITIES TARGETED/EXCLUDED") {
                                            return (
                                                <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            onChange={() => this.handleChange(elt)}
                                                            checked={elt.checked}
                                                            value={elt.checked}
                                                            className="col-checkbox"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={`${elt.name}`} />
                                                </ListItem>
                                            )
                                        }
                                    })
                                    }
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >BIDDER'S INTENTIONS</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "BIDDER'S INTENTIONS" && (elt.name === "Squeeze out" || elt.name === "Merger" || elt.name === "Delisting" || elt.name === "Subsequent OPR-RO")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "BIDDER'S INTENTIONS" && (elt.name === "Other relevant intentions" || elt.name === "New strategy" || elt.name === "Exceptional dividend distribution/  Debt push down" || elt.name === "Disclosure of synergies' amount")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >BIDDER'S STAKE - RESULTS - SQUEEZE OUT</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            {this.props.selectcol.map((elt, i) => {
                                                const name = (elt.name).substring(0, (elt.name).indexOf('C ='));
                                                if (elt.family_name === "BIDDER'S STAKE - RESULTS") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText className="col-name" primary={name.includes("%") ? name.split('%')[0] + "\r\n %" + name.split('%')[1] : name} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "SQUEEZE OUT") {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >FAIRNESS OPINION - TARGET RECOMMENDATION</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "FAIRNESS OPINION - TARGET RECOMMENDATION" && (elt.name === "Fairness opinion" || elt.name === "AMF request for appointing a new expert Art. 261-1-1 II" || elt.name === "AMF objection to the appointment of the expert Art. 261-1-1 III" || elt.name === "Independent expert" || elt.name === "Independent expert's fees")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={4}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "FAIRNESS OPINION - TARGET RECOMMENDATION" && (elt.name === "Ad hoc committee Mission" || elt.name === "Conflicted directors" || elt.name === "Directors' abstention if conflicted")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                        <Col xs={12} md={4}>
                                            {this.props.selectcol.map((elt, i) => {
                                                if (elt.family_name === "FAIRNESS OPINION - TARGET RECOMMENDATION" && (elt.name === "Board attendance" || elt.name === "Target recommendation" || elt.name === "Tender of treasury shares" || elt.name === "Workers council opinion")) {
                                                    return (
                                                        <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onChange={() => this.handleChange(elt)}
                                                                    checked={elt.checked}
                                                                    value={elt.checked}
                                                                    className="col-checkbox"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={`${elt.name}`} />
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary className="col-family"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography >LINK TO AMF DOCUMENTS</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-select">
                        <Grid>
                            <Row>
                                <Col xs={12} md={6}>
                                    {this.props.selectcol.map((elt, i) => {
                                        if (elt.family_name === "LINK TO AMF DOCUMENTS") {
                                            return (
                                                <ListItem key={i} role={undefined} dense button onClick={() => this.handleChange(elt)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            onChange={() => this.handleChange(elt)}
                                                            checked={elt.checked}
                                                            value={elt.checked}
                                                            className="col-checkbox"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={`${elt.name}`} />
                                                </ListItem>
                                            )
                                        }
                                    })
                                    }
                                </Col>
                            </Row>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }
}

export default AddColumns;