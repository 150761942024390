import React from "react";
import { Redirect, Route } from "react-router";
import { fetchUtils, Admin, Resource } from "react-admin";
import "./App.css";
import { createTheme } from "@material-ui/core/styles";
import { UserList } from "./users/Listusers";
import { UserCreate } from "./users/UserCreate";
import { UserShow } from "./users/UserShow";
import { UserEdit } from "./users/UserEdit";
import DealList from "./deals/Listdeals";
import DealShow from "./deals/Showdeal";
import authProvider from "./admin/authProvider";
import MyLoginPage from "./admin/LoginNew";
import StatList from "./statistics/ListStatistics";
import jsonServerProvider from "ra-data-json-server";
import UserIcon from "@material-ui/icons/People";
import ViewListIcon from "@material-ui/icons/ViewList";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import customRoutes from "./util/customRoutes";
import Menu from "./util/Menu";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "./util/IdleModal";
import { useLogout } from "react-admin";
import zIndex from "@material-ui/core/styles/zIndex";

//const MyLoginPage = () => <Login backgroundImage="../IMG_3581_testBD.jpg" />;

const theme = createTheme({
  palette: {
    secondary: {
      main: "#3f51b5",
    },
  },
  sidebar: {
    width: 180,
  },
});

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(process.env.REACT_APP_BASE_OPA_BACKEND, httpClient);

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      redirect: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleClose() {
    this.setState({ showModal: false, isTimedOut: false }, function () {
      this.idleTimer.reset();
    });
  }

  handleLogout() {
    this.setState({ showModal: false, isTimedOut: false, redirect: true }, function () {
      localStorage.removeItem("username");
    });
  }

  render() {
    /*if (this.state.redirect) {
      return <Redirect to='/login' />;
    }*/
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={300000}
          timeout={1000 * 60 * 25}
        />
        <div>
          <React.Fragment>
            <Admin
              theme={theme}
              menu={Menu}
              customRoutes={customRoutes}
              loginPage={MyLoginPage}
              dataProvider={dataProvider}
              authProvider={authProvider}
            >
              {(permissions) => [
                <Resource
                  name="tenderoffers"
                  options={{ label: "Tender Offers" }}
                  list={DealList}
                  show={DealShow}
                  icon={ViewListIcon}
                />,
                permissions === "admin" ? (
                  <Resource
                    name="users"
                    options={{ label: "Users" }}
                    list={UserList}
                    icon={UserIcon}
                    create={UserCreate}
                    show={UserShow}
                    edit={UserEdit}
                  />
                ) : null,
                permissions === "admin" ? (
                  <Resource
                    name="activities"
                    options={{ label: "Activities" }}
                    list={StatList}
                    icon={EqualizerIcon}
                  />
                ) : null,
              ]}
            </Admin>
          </React.Fragment>

          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
          />
        </div>
      </div>
    );
  }

  _onAction(e) {
    // console.log('user did something', e)
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    //console.log('user is idle', e)
    //console.log('last active', this.idleTimer.getLastActiveTime())
    const isTimedOut = this.state.isTimedOut;
    if (localStorage.getItem("username") === null) {
      this.setState({ showModal: false });
    } else {
      if (isTimedOut) {
        localStorage.removeItem("username");
        this.setState({ showModal: false });
      } else {
        this.setState({ showModal: true }, function () {
          this.idleTimer.reset();
          this.setState({ isTimedOut: true });
        });
      }
    }
  }
}

export default App;
